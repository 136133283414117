import React from "react";
import {Colors} from "../Styles";
import AwesomeAlert from 'react-native-awesome-alerts';

export default class Popup extends React.Component {
	render() {
		return (
			<AwesomeAlert
				show={this.props.show}
				showProgress={false}
				title={this.props.title}
				message={this.props.message}
				closeOnTouchOutside
				closeOnHardwareBackPress
				showConfirmButton
				showCancelButton
				confirmText={"Sim"}
				cancelText={"Não"}
				confirmButtonColor={Colors.primary}
				cancelButtonColor={Colors.secondary}
				onConfirmPressed={this.props.onConfirm}
				onCancelPressed={this.props.onDismiss}
				onDismiss={this.props.onDismiss}
			/>
		);
	}
}