import React from "react";
import * as Helper from "../../Helper";
import {calcSize, compare} from "../../Helper";
import {Colors, Styles} from "../../Styles";
import {Animated, FlatList, Text, View} from "react-native-web";
import Collapsible from "react-native-collapsible";
import {Divider, Image} from "react-native-elements";
import {ProgressBar, TouchableRipple} from "react-native-paper";
import {Server as Socket} from "../../App";
import CardFlip from "react-native-card-flip";
import Sparkle from "react-sparkle";
import Slider from "infinite-react-carousel";
import GrowableImage from "../../components/GrowableImage";

export default class Ticket extends React.Component {
	constructor(props) {
		super(props);
		this.resize = () => calcSize(this);
		this.delta = new Animated.Value(30);
		this.missing = require("../../assets/img/missing.jpg");

		this.renderItem = ({item}) => item;
		this.keyExtractor = item => (this.vertical ? "m_" : "d_") + item;

		this.state = {
			userData: JSON.parse(window.localStorage.getItem("user")),
			screenLoaded: false,
			vertical: false,
			form: {},
			showAlert: false,
			showError: false,
			selectedAnime: "",
			cardData: {},
			totalChunks: -1,
			animeIndex: 0
		};
	}

	componentDidMount() {
		calcSize(this);
		this.setState({screenLoaded: true});

		Socket.onmessage = ({data}) => {
			let d = JSON.parse(data);
			if (d.type === "cards") {
				this.setState({
					cardData: {...this.state.cardData, ...d.data.cardData},
					totalChunks: d.total
				}, this.checkCardData)
			}
		}

		Socket.send(JSON.stringify({
			type: "cards",
			data: {
				token: this.state.userData.token
			}
		}))
	}

	checkCardData() {
		if (Object.keys(this.state.cardData).length === this.state.totalChunks) {
			this.animes = Object.values(this.state.cardData)
				.flatMap(a => a)
				.filter(a => a.anime === a.id)
				.sort((a, b) => compare(a.id, b.id));

			this.cards = this.animes.map(a => this.state.cardData[a.id]
				.filter(a => a.anime !== a.id)
				.sort((a, b) => compare(b.rarity, a.rarity) || compare(a.id, b.id))
				.map(c => this.renderCard(c))
			)

			this.forceUpdate();
		}
	}

	renderCard(card) {
		let coverNormal = card.hasNormal ? {uri: `data:image/png;base64,${card.cardNormal}`} : require("../../assets/img/slot.png");
		let coverFoil = card.hasFoil ? {uri: `data:image/png;base64,${card.cardFoil}`} : require("../../assets/img/slot.png");

		return (
			<View style={{marginVertical: "1vmin"}}>
				<CardFlip
					style={{
						height: "25vmin",
						width: "25vmin"
					}}
					ref={ref => this["card_" + card.id] = ref}
				>
					<TouchableRipple onPress={() => this["card_" + card.id].flip()}>
						<Image
							source={coverNormal}
							resizeMode={"contain"}
							containerStyle={{
								height: "25vmin",
								width: "25vmin"
							}}
						/>
					</TouchableRipple>
					<TouchableRipple onPress={() => this["card_" + card.id].flip()}>
						<View>
							<Image
								source={coverFoil}
								resizeMode={"contain"}
								containerStyle={{
									height: "25vmin",
									width: "25vmin"
								}}
							/>
							<Sparkle
								color={"random"}
								fadeOutSpeed={10}
								count={20}
								minSize={5}
								maxSize={15}
								overflowPx={0}
								flicker={false}
							/>
						</View>
					</TouchableRipple>
				</CardFlip>
				<Text style={[Styles.dshText, {
					color: Helper.getColor(card.rarity),
					fontWeight: "bold",
					fontSize: "2.5vmin",
					textAlign: "center",
					marginHorizontal: 0
				}]}>
					{card.name}
				</Text>
			</View>
		)
	}

	render() {
		return (
			<View style={Styles.dshEmbed} onLayout={this.resize}>
				<Text style={Styles.dshTitle}>Coleção Kawaipon</Text>
				<Collapsible collapsed={!this.state.screenLoaded} style={Styles.dshBox}>
					{
						this.animes ?
							<Slider wheel centerMode
									swipe={false}
									slidesToShow={this.state.vertical ? 3 : 5}
									beforeChange={(_, i) => this.setState({animeIndex: i})}
							>
								{
									this.animes.map((c, i) => {
										let card = this.state.cardData[c.id].find(an => an.id === c.id);
										let cover = card && card.hasNormal ? {uri: `data:image/png;base64,${card.cardNormal}`} : this.missing;

										return (
											<View>
												<View style={{alignItems: "center", height: "40vmin"}}>
													<GrowableImage
														source={cover}
														delta={(i - this.state.animeIndex) / this.state.totalChunks}
														maxDelta={0.25}
														deltaStep={4}
														resizeMode={"contain"}
														incHeight={10}
														incWidth={10}
														minHeight={20}
														minWidth={20}
														unit={"vmin"}
														sub={
															<Text style={[Styles.dshText, {
																color: Helper.getColor(card.hasNormal ? 10 : 1),
																fontWeight: "bold",
																fontSize: "2.5vmin",
																textAlign: "center",
																marginHorizontal: 0
															}]}>{c.name}</Text>
														}
													/>
												</View>
											</View>
										)
									})
								}
							</Slider>
							:
							<ProgressBar progress={Object.keys(this.state.cardData).length / this.state.totalChunks}
										 style={{
											 height: "5vmin",
											 marginLeft: "1vmin",
											 borderRadius: "1vmin"
										 }}/>
					}

					{
						this.cards && (
							this.state.vertical ?
								<View>
									<Divider style={{
										marginTop: "2vh",
										height: "0.25vh",
										backgroundColor: Colors.secondary
									}}/>

									<FlatList
										key={"m_"}
										keyExtractor={this.keyExtractor}
										numColumns={4}
										showsVerticalScrollIndicator={false}
										data={this.cards[this.state.animeIndex]}
										renderItem={this.renderItem}
										style={{width: "100%"}}
										columnWrapperStyle={{justifyContent: "center"}}
										removeClippedSubviews={true}
										initialNumToRender={10}
										maxToRenderPerBatch={5}
										updateCellsBatchingPeriod={50}
									/>
								</View> :
								<View>
									<Divider style={{
										marginTop: "2vh",
										height: "0.25vh",
										backgroundColor: Colors.secondary
									}}/>

									<FlatList
										key={"d_"}
										keyExtractor={this.keyExtractor}
										numColumns={6}
										maxToRenderPerBatch={18}
										showsVerticalScrollIndicator={false}
										data={this.cards[this.state.animeIndex]}
										renderItem={this.renderItem}
										style={{width: "100%"}}
										columnWrapperStyle={{justifyContent: "center"}}
									/>
								</View>
						)
					}
				</Collapsible>
			</View>
		)
	}

}