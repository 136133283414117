import React from "react";
import {calcSize} from "../../Helper";
import {Colors, Styles} from "../../Styles";
import {Text, TextInput, View} from "react-native-web";
import Collapsible from "react-native-collapsible";
import {Input} from "react-native-elements";
import Button from "../../components/Button";
import Popup from "../../components/Popup";
import Error from "../../components/Error";
import {Server as Socket} from "../../App";
import * as Async from "async";

export default class Ticket extends React.Component {
	constructor(props) {
		super(props);
		this.resize = () => calcSize(this);

		this.state = {
			userData: JSON.parse(window.localStorage.getItem("user")),
			screenLoaded: false,
			vertical: false,
			form: {},
			showAlert: false,
			showError: false
		};
	}

	componentDidMount() {
		calcSize(this);
		this.setState({screenLoaded: true});
	}

	showAlert() {
		this.setState({showAlert: true});
	}

	hideAlert() {
		this.setState({showAlert: false});
	}

	showError() {
		this.setState({showError: true});
	}

	hideError() {
		this.setState({showError: false});
	}

	render() {
		return (
			<View style={Styles.dshEmbed} onLayout={this.resize}>
				<Text style={Styles.dshTitle}>Feedback</Text>
				<Collapsible collapsed={!this.state.screenLoaded} style={Styles.dshBox}>
					<Text style={Styles.dshSubText}>{
						"Use o formulário abaixo para enviar aos desenvolvedores um ticket contendo sua dúvida/sugestão/crítica!\n"
					}</Text>
					<Text style={[Styles.dshSubText, {color: "red"}]}>Por favor evite enviar tickets contendo SPAM ou sem motivo algum, pois você poderá ser impedido de acessar este site por tempo indeterminado.</Text>

					<Text style={Styles.dshText}>Assunto:</Text>
					<Input
						value={this.state.form.subject}
						maxLength={100}
						placeholder="Digite um assunto"
						inputStyle={[Styles.dshInput]}
						containerStyle={{
							borderWidth: "0.2vmin",
							borderRadius: "1vmax",
							borderColor: "#f0d5eb",
							padding: "1vmin"
						}}
						onChangeText={t => {
							this.state.form.subject = t;
							this.forceUpdate();
						}}
					/>

					<View style={{margin: "1vh"}}/>

					<TextInput
						value={this.state.form.message}
						style={[Styles.dshTextArea, {height: "40vmin"}]}
						multiline
						maxLength={2000}
						onChangeText={t => {
							this.state.form.message = t;
							this.forceUpdate();
						}}
					/>

					<View style={{margin: "2vh"}}/>

					<Button
						color={Colors.primary}
						hoverColor={Colors.primaryLighter}
						title={"Enviar"}
						buttonStyle={Styles.dshButton}
						titleStyle={Styles.dshText}
						onPress={() => {
							if (!this.state.form.subject || !this.state.form.message) {
								this.showError();
								return;
							}

							Async.waterfall([
								done => {
									Socket.send(JSON.stringify({
										type: "ticket",
										data: {
											token: this.state.userData.token,
											...this.state.form
										}
									}));
									done();
								},
								done => {
									this.setState({
										form: {
											subject: "",
											message: ""
										}
									});
									this.showAlert();
									done();
								}
							]);
						}}
					/>
				</Collapsible>
				<Popup
					show={this.state.showAlert}
					title={"Enviado!"}
					message={"Ticket enviado com sucesso!"}
					onClose={() => this.hideAlert()}
				/>
				<Error
					show={this.state.showError}
					title={"Campos vazios!"}
					message={"Por favor, preencha todos os campos do formulário."}
					onClose={() => this.hideError()}
				/>
			</View>
		);
	}
}