import React from "react";
import {Colors} from "../Styles";
import AwesomeAlert from 'react-native-awesome-alerts';

export default class Error extends React.Component {
	render() {
		return (
			<AwesomeAlert
				show={this.props.show}
				showProgress={false}
				title={this.props.title}
				message={this.props.message}
				closeOnTouchOutside
				closeOnHardwareBackPress
				showConfirmButton
				confirmText={"Ok!"}
				confirmButtonColor={Colors.error}
				onConfirmPressed={this.props.onClose}
				onDismiss={this.props.onClose}
			/>
		);
	}
}