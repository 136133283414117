import React from "react";
import {TextInput, View} from "react-native";
import {Button} from "react-native-elements";
import {AntDesign} from "@expo/vector-icons";

export default class Spinner extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <View style={[{...this.props.style}, {flexDirection: "row", height: "2em"}]}>
			<TextInput
				style={{
					backgroundColor: "white",
					textAlign: "center",
					width: "3em"
				}}
				value={this.props.value}
				onChangeText={t => {
					if (!Number.isNaN(t)) {
						this.props?.onChange(parseInt(t));
					}
				}}
			/>
			<View>
				<Button
					buttonStyle={{height: "1em", width: "2em", borderRadius: 0}}
					onPress={this.props.onIncrease}
					icon={
						<AntDesign
							name={"caretup"}
							color={"#000"}
							size={"0.5em"}
						/>
					}
				/>
				<Button
					buttonStyle={{height: "1em", width: "2em", borderRadius: 0}}
					onPress={this.props.onDecrease}
					icon={
						<AntDesign
							name={"caretdown"}
							color={"#000"}
							size={"0.5em"}
						/>
					}
				/>
			</View>
		</View>;
	}
}