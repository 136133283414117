import React from "react";
import Home from "./pages/Home";
import {createStackNavigator} from "react-navigation-stack";
import Dashboard from "./pages/Dashboard";
import Loading from "./pages/Loading";
import {createBrowserApp} from "@react-navigation/web/src";
import Unauthorized from "./pages/Unauthorized";
import ReconnectingWebsocket from "reconnecting-websocket";
import ShiroCanvas from "./pages/ShiroCanvas";
import EmbedBuilder from "./pages/EmbedBuilder";
import CABuilder from "./pages/CABuilder";

export const Server = new ReconnectingWebsocket("wss://socket.shirojbot.site/server");

const MainNavigator = createStackNavigator(
	{
		Home: Home,
		Loading: Loading,
		Dashboard: Dashboard,
		Unauthorized: Unauthorized,
		ShiroCanvas: ShiroCanvas,
		EmbedBuilder: EmbedBuilder,
		CABuilder: CABuilder
	},
	{
		headerMode: "none",
		initialRouteName: "Home"
	}
);

const App = createBrowserApp(MainNavigator);

export default App;
