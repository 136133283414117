import React from "react";
import {TextInput, View} from "react-native";
import {Text} from "react-native-web";
import {Colors} from "../Styles";

export default class NamedField extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<View style={{marginLeft: 8, marginTop: 8, flexDirection: "row", alignItems: "center"}}>
				<Text style={{
					flex: 1,
					font: "16px Whitney, \"Helvetica Neue\", Helvetica",
					color: "white"
				}}>
					{this.props.children}
				</Text>
				<TextInput
					editable={!this.props.disabled}
					value={this.props.value}
					multiline={this.props.multiline}
					onChangeText={this.props.onChangeText}
					maxLength={this.props.maxLength}
					style={{
						flex: 4,
						marginLeft: 8,
						backgroundColor: this.props.disabled ? Colors.secondary : Colors.secondaryLighter,
						fontSize: 16,
						borderRadius: 4,
						padding: 8,
						height: this.props.multiline ? 100 : "auto"
					}}
				/>
			</View>
		)
	}
}