import React from "react";
import {Animated, Easing, SafeAreaView, Text, View} from "react-native-web";
import {Colors, Styles} from "../Styles";
import {ProgressBar} from "react-native-paper";
import {Server} from "../App";

export default class Loading extends React.Component {
	static navigationOptions = {
		title: "Loading"
	};

	static path = "Loading";

	constructor(props) {
		super(props);
		this.letters = [
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0)
		];

		this.state = {
			loaded: false,
		}
	}

	componentDidMount() {
		Server.onmessage = ({data}) => {
			let u = JSON.parse(data);
			let date = new Date();
			window.localStorage.setItem("user", JSON.stringify({
				token: u.token,
				id: u.id,
				name: u.username,
				avatar: `https://cdn.discordapp.com/avatars/${u.id}/${u.avatar}.png?size=512`,
				sessionDate: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
			}));
			this.setState({loaded: true});
		};

		for (const a of window.location.search.substring(1).split("&")) {
			let key = a.split("=")[0];
			let value = a.split("=")[1];
			if (key && value) window.sessionStorage.setItem(key, value);
		}

		if (window.sessionStorage.getItem("s"))
			Server.onopen = () => Server.send(JSON.stringify({
				type: "login",
				data: window.sessionStorage.getItem("s")
			}));

		this.floatingText();
	}

	floatingLetter(value) {
		return Animated.sequence([
			Animated.timing(value, {
				toValue: 100,
				duration: 500,
				easing: Easing.inOut(Easing.quad)
			}),
			Animated.timing(value, {
				toValue: 0,
				duration: 500,
				easing: Easing.inOut(Easing.quad)
			})
		]);
	}

	floatingText() {
		Animated.stagger(250, [
			this.floatingLetter(this.letters[0]),
			this.floatingLetter(this.letters[1]),
			this.floatingLetter(this.letters[2]),
			this.floatingLetter(this.letters[3]),
			this.floatingLetter(this.letters[4]),
			this.floatingLetter(this.letters[5]),
			this.floatingLetter(this.letters[6]),
			this.floatingLetter(this.letters[7]),
			this.floatingLetter(this.letters[8]),
			this.floatingLetter(this.letters[9])
		]).start(() => {
			this.floatingText();
		});
	}

	makeLetter(text, index) {
		const fluctuation = this.letters[index].interpolate({
			inputRange: [0, 100],
			outputRange: ["0vh", "-3vh"]
		});

		return (
			<Animated.View style={{transform: [{translateY: fluctuation}]}}>
				<Text style={[Styles.header, Styles.headerShadow]}>{text}</Text>
				<Text style={[Styles.header, Styles.headerShadow, {position: "absolute"}]}>{text}</Text>
				<Text style={[Styles.header, Styles.headerMask, {position: "absolute"}]}>{text}</Text>
				<Text style={[Styles.header, {
					position: "absolute",
					textShadowColor: "#fffa",
					textShadowRadius: "0.5vmax",
					color: "transparent"
				}]}>{text}</Text>
			</Animated.View>
		);
	}

	render() {
		if (this.state.loaded) setTimeout(() => window.open(`${window.location.origin}/Dashboard`, "_self"), 3000);

		return (
			<SafeAreaView style={{
				height: "100%",
				justifyContent: "flex-end",
				padding: "1vh",
				backgroundColor: Colors.containerDark
			}}>
				<View style={{
					alignSelf: "flex-end",
					alignItems: "flex-end"
				}}>
					<View style={{flexDirection: "row",}}>
						{this.makeLetter("C", 0)}
						{this.makeLetter("a", 1)}
						{this.makeLetter("r", 2)}
						{this.makeLetter("r", 3)}
						{this.makeLetter("e", 4)}
						{this.makeLetter("g", 5)}
						{this.makeLetter("a", 6)}
						{this.makeLetter("n", 7)}
						{this.makeLetter("d", 8)}
						{this.makeLetter("o", 9)}
					</View>
					{this.state.loaded ? <ProgressBar indeterminate style={Styles.bigProgressBar}/> : null}
				</View>
			</SafeAreaView>
		);
	}
}
