import React from "react";
import {Animated, Easing, SafeAreaView, Text, View, Linking} from "react-native-web";
import {Colors, Styles} from "../Styles";
import Button from "../components/Button";
import {AntDesign} from "@expo/vector-icons";

export default class Unauthorized extends React.Component {
	static navigationOptions = {
		title: "Unauthorized"
	};

	static path = "Unauthorized";

	constructor(props) {
		super(props);
		this.letters = [
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0),
			new Animated.Value(0)
		];

		this.state = {
			loaded: 0,
		}
	}

	componentDidMount() {
		this.floatingText();
	}

	floatingLetter(value) {
		return Animated.sequence([
			Animated.timing(value, {
				toValue: 100,
				duration: 500,
				easing: Easing.inOut(Easing.quad)
			}),
			Animated.timing(value, {
				toValue: 0,
				duration: 500,
				easing: Easing.inOut(Easing.quad)
			})
		]);
	}

	floatingText() {
		Animated.stagger(250, [
			this.floatingLetter(this.letters[0]),
			this.floatingLetter(this.letters[1]),
			this.floatingLetter(this.letters[2]),
			this.floatingLetter(this.letters[3]),
			this.floatingLetter(this.letters[4]),
			this.floatingLetter(this.letters[5]),
			this.floatingLetter(this.letters[6]),
			this.floatingLetter(this.letters[7]),
			this.floatingLetter(this.letters[8]),
			this.floatingLetter(this.letters[9]),
			this.floatingLetter(this.letters[10]),
			this.floatingLetter(this.letters[11]),
			this.floatingLetter(this.letters[12]),
			this.floatingLetter(this.letters[13])
		]).start(() => {
			this.floatingText();
		});
	}

	makeLetter(text, index) {
		const fluctuation = this.letters[index].interpolate({
			inputRange: [0, 100],
			outputRange: ["0vh", "-3vh"]
		});

		return (
			<Animated.View style={{transform: [{translateY: fluctuation}]}}>
				<Text style={[Styles.header, Styles.headerShadow]}>{text}</Text>
				<Text style={[Styles.header, Styles.headerShadow, {position: "absolute"}]}>{text}</Text>
				<Text style={[Styles.header, Styles.headerMask, {position: "absolute"}]}>{text}</Text>
				<Text style={[Styles.header, {
					position: "absolute",
					textShadowColor: "#fffa",
					textShadowRadius: "0.5vmax",
					color: "transparent"
				}]}>{text}</Text>
			</Animated.View>
		);
	}

	render() {
		if (this.state.loaded === 5) setTimeout(() => Linking.openURL("../Dashboard"), 1000);

		return (
			<SafeAreaView style={{
				height: "100%",
				justifyContent: "flex-end",
				padding: "1vh",
				backgroundColor: Colors.containerDark
			}}>
				<View style={{
					alignSelf: "flex-end",
					alignItems: "flex-end"
				}}>
					<View style={{flexDirection: "row",}}>
						{this.makeLetter("N", 0)}
						{this.makeLetter("ã", 1)}
						{this.makeLetter("o", 2)}
						{this.makeLetter(" ", 3)}
						{this.makeLetter("a", 4)}
						{this.makeLetter("u", 5)}
						{this.makeLetter("t", 6)}
						{this.makeLetter("o", 7)}
						{this.makeLetter("r", 8)}
						{this.makeLetter("i", 9)}
						{this.makeLetter("z", 10)}
						{this.makeLetter("a", 11)}
						{this.makeLetter("d", 12)}
						{this.makeLetter("o", 13)}
					</View>
					<Button
						color={Colors.primary}
						hoverColor={Colors.primaryLighter}
						icon={
							<AntDesign
								name={"logout"}
								color={Colors.pinkText}
								size={"3vmax"}
							/>
						}
						hoverIcon={
							<AntDesign
								name={"logout"}
								color={Colors.pinkText}
								size={"3vmax"}
							/>
						}
						title={"Voltar"}
						buttonStyle={[Styles.smallButton, {padding: "1vmax"}]}
						containerStyle={[Styles.smallButton, Styles.boxShadowDown, {
							margin: "0.5vmax"
						}]}
						titleStyle={{
							fontFamily: "Doreking",
							color: Colors.pinkText,
							fontSize: "1.5vmax",
							marginLeft: "0.5vmax"
						}}
						onPress={() => {
							window.localStorage.clear();
							window.open(`${window.location.origin}/Home`, "_self");
						}}
					/>
				</View>
			</SafeAreaView>
		);
	}
}
