import React from "react";
import {Dimensions, KeyboardAvoidingView, SafeAreaView, Text, View} from "react-native-web";
import {Colors, Discord} from "../Styles";
import caModel from "../model/CustomAnswer.json"
import {Clipboard, Image, ScrollView} from "react-native";
import Markdown, {MarkdownIt} from "react-native-markdown-display";
import {Button} from "react-native-elements";
import {AntDesign} from "@expo/vector-icons";
import {Snackbar} from "react-native-paper";
import NamedField from "../components/NamedField";
import {calcSize, clamp} from "../Helper";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";
import Svg, {Path} from "react-native-svg";
import NamedRadio from "../components/NamedRadio";
import NamedSpinner from "../components/NamedSpinner";

export default class Unauthorized extends React.Component {
	static navigationOptions = {
		title: "CABuilder"
	};

	static path = "CABuilder";
	ca = JSON.parse(JSON.stringify(caModel));

	rules = {
		body: (node, children) => <Text key={node.key} style={{
			font: `${1 * this.sizeFac()}rem Whitney, "Helvetica Neue", Helvetica`,
			wordBreak: "break-word",
			color: "#dcddde"
		}}>
			{children}
		</Text>,
		code_inline: node => <Text key={node.key} style={{
			fontFamily: "Consolas, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace",
			wordBreak: "break-word",
			color: "#dcddde",
			padding: `${0.2 * this.sizeFac()}em`,
			margin: `${-0.2 * this.sizeFac()}em 0`,
			borderRadius: 4.5,
			fontSize: "85%",
			backgroundColor: Discord.backgroundDark
		}}>
			{node.content}
		</Text>,
		fence: node => {
			if (typeof node.content === "string" && node.content.charAt(node.content.length - 1) === "\n") {
				node.content = node.content.substring(0, node.content.length - 1);
			}

			return <Text key={node.key} style={{
				fontFamily: "Consolas, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace",
				wordBreak: "break-word",
				color: "#b9bbbe",
				padding: 10.5,
				margin: `${-0.2 * this.sizeFac()}em 0`,
				borderRadius: 3,
				fontSize: `${0.875 * this.sizeFac()}rem`,
				lineHeight: `${1.125 * this.sizeFac()}rem`,
				backgroundColor: Discord.backgroundDark
			}}>
				{node.content}
			</Text>
		}
	}

	mdIgnore = MarkdownIt({typographer: true}).disable([
		"blockquote",
		"code",
		"heading",
		"hr",
		"html_block",
		"lheading",
		"list",
		"reference",
		"autolink",
		"backticks",
		"entity",
		"escape",
		"html_inline",
		"image",
		"link"
	]);

	constructor(props) {
		super(props);

		this.resize = () => calcSize(this);
		this.sizeFac = () => this.state.vertical ? 1 : 1.5;
		this.menu = React.createRef();

		this.state = {
			vertical: false,
			toastVisible: false,
			toastMessage: "",
			menuOpen: false
		}
	}

	removeEmpty(obj) {
		return Object.entries(obj)
			.filter(([_, v]) => (v.constructor === Boolean && v) || Object.entries(v).length > 0)
			.reduce(
				(acc, [k, v]) => ({...acc, [k]: v === Object(v) && v.constructor !== Array ? this.removeEmpty(v) : v}),
				{}
			);
	}

	caConfig() {
		return (
			<KeyboardAvoidingView style={{flex: 2, backgroundColor: Colors.container}}>
				<ScrollView style={{padding: 16}}>
					<View style={{marginBottom: 16}}>
						<Text style={{font: "bold 22px Whitney, \"Helvetica Neue\", Helvetica", color: "white"}}>
							Configurações
						</Text>
						<NamedField
							value={this.ca.trigger}
							maxLength={128}
							onChangeText={t => {
								this.ca.trigger = t;
								this.forceUpdate();
							}}>Gatilho:</NamedField>
						<NamedField
							value={this.ca.answer}
							maxLength={1024}
							onChangeText={t => {
								this.ca.answer = t;
								this.forceUpdate();
							}}>Resposta:</NamedField>
						<NamedRadio
							value={this.ca.anywhere}
							onPress={() => {
								this.ca.anywhere = !this.ca.anywhere;
								this.forceUpdate();
							}}>Qualquer lugar</NamedRadio>
						<NamedSpinner
							value={this.ca.chance}
							onIncrease={() => {
								this.ca.chance = clamp(this.ca.chance + 1, 1, 100);
								this.forceUpdate();
							}}
							onDecrease={() => {
								this.ca.chance = clamp(this.ca.chance - 1, 1, 100);
								this.forceUpdate();
							}}
							onChange={v => {
								if (Number.isNaN(v)) v = 1;

								this.ca.chance = clamp(v, 1, 100);
								this.forceUpdate();
							}}
						>Chance (%)</NamedSpinner>
					</View>
					<View style={{marginBottom: 16}}>
						<Text style={{font: "bold 18px Whitney, \"Helvetica Neue\", Helvetica", color: "white"}}>
							Filtros (separados por vírgula)
						</Text>
						<NamedField
							value={this.ca.users.join(",")}
							onChangeText={t => {
								this.ca.users = t.replace(" ", "")
									.replace("\"", "")
									.split(",")
									.filter(v => !v || !Number.isNaN(parseInt(v)));
								this.forceUpdate();
							}}>Usuários:</NamedField>
						<NamedField
							value={this.ca.channels.join(",")}
							onChangeText={t => {
								this.ca.channels = t.replace(" ", "")
									.replace("\"", "")
									.split(",")
									.filter(v => !v || !Number.isNaN(parseInt(v)));
								this.forceUpdate();
							}}>Canais:</NamedField>
					</View>
				</ScrollView>
				<View style={{
					bottom: 0,
					flexDirection: "row",
					width: "100%",
					justifyContent: "center",
					padding: 8
				}}>
					<Button
						title={"Copiar JSON"}
						style={{marginHorizontal: 8}}
						buttonStyle={{backgroundColor: Discord.success}}
						titleStyle={{fontSize: this.state.vertical ? "1em" : "1.5em"}}
						onPress={() => {
							this.ca.users = this.ca.users.filter(s => s !== "")
							this.ca.channels = this.ca.channels.filter(s => s !== "")

							Clipboard.setString(JSON.stringify(this.removeEmpty(this.ca)));
							this.setState({
								toastVisible: true,
								toastMessage: "Copiado com sucesso!"
							})
							setTimeout(() => {
								this.setState({
									toastVisible: false,
									toastMessage: ""
								});
							}, 5000)
						}}
						icon={
							<AntDesign
								name={"save"}
								color={"white"}
								size={this.state.vertical ? "1.5em" : "2em"}
							/>
						}
					/>
					{this.state.vertical
						? <Button
							title={"Visualizar"}
							style={{marginHorizontal: 8}}
							titleStyle={{fontSize: this.state.vertical ? "1em" : "1.5em"}}
							buttonStyle={{backgroundColor: Discord.primary}}
							onPress={() => {
								this.menu.current.closeDrawer();
								this.setState({menuOpen: false});
							}}
							icon={
								<AntDesign
									name={"menu-fold"}
									color={"white"}
									size={this.state.vertical ? "1.5em" : "2em"}
								/>
							}
						/> : null}
					<Button
						title={"Limpar"}
						style={{marginHorizontal: 8}}
						buttonStyle={{backgroundColor: Discord.danger}}
						titleStyle={{fontSize: this.state.vertical ? "1em" : "1.5em"}}
						onPress={() => {
							this.ca = JSON.parse(JSON.stringify(caModel));
							this.setState({
								toastVisible: true,
								toastMessage: "Campos limpos com sucesso!"
							}, this.forceUpdate)
							setTimeout(() => {
								this.setState({
									toastVisible: false,
									toastMessage: ""
								});
							}, 5000)
						}}
						icon={
							<AntDesign
								name={"delete"}
								color={"white"}
								size={this.state.vertical ? "1.5em" : "2em"}
							/>
						}
					/>
				</View>
			</KeyboardAvoidingView>
		);
	}

	caPreview() {
		return (
			<View style={{flex: 5, margin: "5%", alignItems: "center", justifyContent: "center", pointerEvents: "none"}}>
				<View style={{
					backgroundColor: Discord.backgroundLight,
					padding: 16,
					borderRadius: 4
				}}>
					<View style={{width: "100%"}}>
						<View style={{flexDirection: "row", minHeight: `${2.75 * this.sizeFac()}rem`}}>
							<Image
								style={{
									width: 40 * this.sizeFac(),
									height: 40 * this.sizeFac(),
									borderRadius: "50%"
								}}
								source={{uri: "https://i.imgur.com/3QC1VuB.png"}}
							/>
							<View style={{marginLeft: 10 * this.sizeFac(), flex: 1}}>
								<View style={{flexDirection: "row", alignItems: "flex-end"}}>
									<Text style={{
										font: `bold ${1 * this.sizeFac()}rem Whitney, "Helvetica Neue", Helvetica`,
										color: "white",
										marginRight: `${0.25 * this.sizeFac()}rem`,
										lineHeight: `${1.375 * this.sizeFac()}rem`
									}}>
										Usuário
									</Text>
									<Text style={{
										font: `${0.75 * this.sizeFac()}rem Whitney, "Helvetica Neue", Helvetica`,
										color: "#72767d",
										marginLeft: `${0.25 * this.sizeFac()}rem`,
										lineHeight: `${1.375 * this.sizeFac()}rem`
									}}>
										{"Hoje às " + new Date().getHours().toString().padStart(2, "0") + ":" + new Date().getMinutes().toString().padStart(2, "0")}
									</Text>
								</View>
								<View style={{marginVertical: -10 * this.sizeFac()}}>
									<Markdown rules={this.rules} markdownit={this.mdIgnore}>
										{this.ca.trigger ? (this.ca.anywhere ? `Veja só, "${this.ca.trigger.replace("```", "\n```\n")}" mesmo em uma frase!` : this.ca.trigger.replace("```", "\n```\n")) : ""}
									</Markdown>
								</View>
							</View>
						</View>
						<View style={{flexDirection: "row", minHeight: `${2.75 * this.sizeFac()}rem`, marginTop: `${1.0625 * this.sizeFac()}rem`}}>
							<Image
								style={{
									width: 40 * this.sizeFac(),
									height: 40 * this.sizeFac(),
									borderRadius: "50%"
								}}
								source={{uri: "https://cdn.discordapp.com/avatars/572413282653306901/c621d0c905fe654f388b3ac3f23b362b.png?size=60"}}
							/>
							<View style={{marginLeft: 10 * this.sizeFac(), flex: 1}}>
								<View style={{flexDirection: "row", alignItems: "flex-end"}}>
									<Text style={{
										font: `bold ${1 * this.sizeFac()}rem Whitney, "Helvetica Neue", Helvetica`,
										color: "white",
										marginRight: `${0.25 * this.sizeFac()}rem`,
										lineHeight: `${1.375 * this.sizeFac()}rem`
									}}>
										Shiro
									</Text>
									<View style={{
										backgroundColor: Discord.primary,
										flexDirection: "row",
										borderRadius: `${0.1875 * this.sizeFac()}rem`,
										paddingHorizontal: `${0.275 * this.sizeFac()}rem`,
										top: `${0.1 * this.sizeFac()}rem`,
										alignSelf: "flex-start",
										marginRight: `${0.25 * this.sizeFac()}rem`
									}}>
										<Svg style={{marginLeft: `${-0.25 * this.sizeFac()}rem`}} height={`${0.9375 * this.sizeFac()}rem`} width={`${0.9375 * this.sizeFac()}rem`}>
											<Path
												d={`
												M ${7.4 * this.sizeFac()} ${11.17 * this.sizeFac()}
												L ${4 * this.sizeFac()} ${8.62 * this.sizeFac()}
												L ${5 * this.sizeFac()} ${7.26 * this.sizeFac()}
												l ${2 * this.sizeFac()} ${1.53 * this.sizeFac()}
												L ${10.64 * this.sizeFac()} ${4 * this.sizeFac()}
												l ${1.36 * this.sizeFac()} ${1 * this.sizeFac()}
												Z
												`}
												fill={"white"}
											/>
										</Svg>
										<Text style={{
											font: `bold ${10 * this.sizeFac()}px Whitney, "Helvetica Neue", Helvetica`,
											color: "white",
											lineHeight: `${0.9375 * this.sizeFac()}rem`
										}}>
											BOT
										</Text>
									</View>
									<Text style={{
										font: `${0.75 * this.sizeFac()}rem Whitney, "Helvetica Neue", Helvetica`,
										color: "#72767d",
										marginLeft: `${0.25 * this.sizeFac()}rem`,
										lineHeight: `${1.375 * this.sizeFac()}rem`
									}}>
										{"Hoje às " + new Date().getHours().toString().padStart(2, "0") + ":" + new Date().getMinutes().toString().padStart(2, "0")}
									</Text>
								</View>
								<View style={{marginVertical: -10 * this.sizeFac()}}>
									<Markdown rules={this.rules} markdownit={this.mdIgnore}>
										{this.ca.answer.replace("```", "\n```\n")}
									</Markdown>
								</View>
							</View>
						</View>
					</View>
				</View>
			</View>
		);
	}

	componentDidMount() {
		this.resize();
		Dimensions.addEventListener("change", this.resize);
	}

	componentWillUnmount() {
		Dimensions.removeEventListener("change", this.resize);
	}

	render() {
		return (
			<SafeAreaView style={{
				flexDirection: "row",
				height: "100%",
				backgroundColor: Colors.containerDark
			}}>
				{
					this.state.vertical
						? <DrawerLayout
							ref={this.menu}
							drawerWidth={Dimensions.get("screen").width}
							drawerLockMode={this.state.menuOpen ? "locked-open" : "locked-closed"}
							renderNavigationView={() => this.caConfig()}>
							<View style={{flex: 1}}>
								<Button
									style={{position: "absolute", top: 16, left: 8, zIndex: 99}}
									buttonStyle={{height: 50, width: 50, backgroundColor: "#0007"}}
									onPress={() => {
										this.menu.current.openDrawer();
										this.setState({menuOpen: true});
									}}
									icon={
										<AntDesign
											name={"menu-unfold"}
											color={"white"}
											size={"2em"}
										/>
									}
								/>
								{this.caPreview()}
							</View>
						</DrawerLayout>
						: <View style={{flex: 1, flexDirection: "row"}}>
							{this.caConfig()}
							{this.caPreview()}
						</View>
				}
				<Snackbar
					style={{
						position: "absolute",
						bottom: 8,
						right: 16
					}}
					visible={this.state.toastVisible}
				>
					{this.state.toastMessage}
				</Snackbar>
			</SafeAreaView>
		);
	}
}
