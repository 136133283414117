import React from "react";
import {TouchableOpacity} from "react-native";
import {Text} from "react-native-web";

export default class InlineUrl extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hover: false
		}
	}

	render() {
		if (this.props.href)
			return <TouchableOpacity
				onClick={() => window.open(this.props.href, "_blank")}
				onMouseEnter={() => this.setState({hover: true})}
				onMouseLeave={() => this.setState({hover: false})}
			>
				<Text style={[{...this.props.style}, {
					color: this.props.linkColor || "#00b0f4",
					textDecoration: this.state.hover ? "underline" : "none"
				}]}>
					{this.props.text || this.props.href}
				</Text>
			</TouchableOpacity>;
		else return <Text style={this.props.style}>
			{this.props.text || this.props.href}
		</Text>
	}
}