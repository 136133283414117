import React from "react";
import {Dimensions, KeyboardAvoidingView, SafeAreaView, Text, View} from "react-native-web";
import {Colors, Discord} from "../Styles";
import embedModel from "../model/DiscordEmbed.json"
import {Clipboard, Image, ScrollView} from "react-native";
import InlineUrl from "../components/InlineUrl";
import Markdown, {MarkdownIt} from "react-native-markdown-display";
import * as probe from "probe-image-size";
import {Button} from "react-native-elements";
import {AntDesign} from "@expo/vector-icons";
import {Snackbar} from "react-native-paper";
import NamedField from "../components/NamedField";
import NamedRadio from "../components/NamedRadio";
import {calcSize} from "../Helper";
import DrawerLayout from "react-native-gesture-handler/DrawerLayout";

export default class Unauthorized extends React.Component {
	static navigationOptions = {
		title: "EmbedBuilder"
	};

	static path = "EmbedBuilder";
	embed = JSON.parse(JSON.stringify(embedModel));

	rules = {
		body: (node, children) => <Text key={node.key} style={{
			font: "14px Whitney, \"Helvetica Neue\", Helvetica",
			wordBreak: "break-word",
			color: "#dcddde"
		}}>
			{children}
		</Text>,
		code_inline: node => <Text key={node.key} style={{
			fontFamily: "Consolas, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace",
			wordBreak: "break-word",
			color: "#dcddde",
			padding: "0.2em",
			margin: "-0.2em 0",
			borderRadius: 3,
			fontSize: "85%",
			backgroundColor: Discord.backgroundDark
		}}>
			{node.content}
		</Text>,
		fence: node => {
			if (typeof node.content === "string" && node.content.charAt(node.content.length - 1) === "\n") {
				node.content = node.content.substring(0, node.content.length - 1);
			}

			return <Text key={node.key} style={{
				fontFamily: "Consolas, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace",
				wordBreak: "break-word",
				color: "#b9bbbe",
				padding: 7,
				margin: "-0.2em 0",
				borderRadius: 3,
				fontSize: "0.875rem",
				lineHeight: "1.125rem",
				backgroundColor: Discord.backgroundDark
			}}>
				{node.content}
			</Text>
		},
		link: (node, children) => <InlineUrl
			key={node.key}
			style={{
				font: "14px Whitney, \"Helvetica Neue\", Helvetica",
				wordBreak: "break-word"
			}}
			href={node.attributes.href}
			text={children}
			linkColor={"#1ea2d5"}
		/>,
	}

	mdIgnore = MarkdownIt({typographer: true}).disable([
		"blockquote",
		"code",
		"heading",
		"hr",
		"html_block",
		"lheading",
		"list",
		"reference",
		"autolink",
		"backticks",
		"entity",
		"escape",
		"html_inline",
		"image"
	]);

	constructor(props) {
		super(props);

		this.resize = () => calcSize(this);
		this.menu = React.createRef();

		this.state = {
			vertical: false,
			toastVisible: false,
			toastMessage: "",
			menuOpen: false,
			img: null,
			imgSize: {
				width: 0,
				height: 0
			},
			automated: false
		}
	}

	makeFields() {
		const rows = [];

		let row = [];
		for (const f of this.embed.fields) {
			if (!f.inline) {
				rows.push(
					<View style={{flexDirection: "row"}}>
						{row}
					</View>
				)

				row = [];

				rows.push(
					<View style={{flexDirection: "row"}}>
						<View style={{maxWidth: 520 / 3, marginRight: "1em", marginTop: 5}}>
							<Text style={{
								font: "600 14px Whitney, \"Helvetica Neue\", Helvetica",
								color: "white",
								marginBottom: -5
							}}>{f.name}</Text>
							<Markdown rules={this.rules} markdownit={this.mdIgnore}>
								{f.value}
							</Markdown>
						</View>
					</View>
				)
			} else {
				row.push(
					<View style={{maxWidth: 520 / 3, marginRight: "1em", marginTop: 5}}>
						<Text style={{
							font: "600 14px Whitney, \"Helvetica Neue\", Helvetica",
							color: "white",
							marginBottom: -5
						}}>{f.name}</Text>
						<Markdown rules={this.rules} markdownit={this.mdIgnore}>
							{f.value}
						</Markdown>
					</View>
				)
			}

			if (row.length === 3) {
				rows.push(
					<View style={{flexDirection: "row"}}>
						{row}
					</View>
				)

				row = [];
			}
		}

		if (row.length > 0) {
			rows.push(
				<View style={{flexDirection: "row"}}>
					{row}
				</View>
			)
		}

		return rows;
	}

	getImageInfo(url) {
		probe(url)
			.then(info => {
				this.setState({
					img: url,
					imgSize: {
						width: info.width,
						height: info.height
					}
				})
			})
			.catch(() => {
				this.setState({
					img: null,
					imgSize: {
						width: 0,
						height: 0
					}
				});
			});
	}

	removeEmpty(obj) {
		return Object.entries(obj)
			.filter(([_, v]) => (v.constructor === Boolean && v) || Object.entries(v).length > 0)
			.reduce(
				(acc, [k, v]) => ({...acc, [k]: v === Object(v) && v.constructor !== Array ? this.removeEmpty(v) : v}),
				{}
			);
	}

	embedConfig() {
		return (
			<KeyboardAvoidingView style={{flex: 2, backgroundColor: Colors.container}}>
				<ScrollView style={{padding: 16}}>
					<View style={{marginBottom: 16}}>
						<Text style={{font: "bold 22px Whitney, \"Helvetica Neue\", Helvetica", color: "white"}}>
							Autor
						</Text>
						<NamedField
							value={this.embed.author.name}
							maxLength={256}
							onChangeText={t => {
								this.embed.author.name = t;
								this.forceUpdate();
							}}>Título:</NamedField>
						<NamedField
							value={this.embed.author.icon}
							onChangeText={t => {
								this.embed.author.icon = t;
								this.forceUpdate();
							}}>Ícone:</NamedField>
						<NamedField
							value={this.embed.author.url}
							onChangeText={t => {
								this.embed.author.url = t;
								this.forceUpdate();
							}}>Link:</NamedField>
					</View>
					<View style={{marginBottom: 16}}>
						<Text style={{font: "bold 22px Whitney, \"Helvetica Neue\", Helvetica", color: "white"}}>
							Cabeçalho
						</Text>
						<NamedField
							disabled={this.state.automated}
							value={this.embed.title.name}
							maxLength={256}
							onChangeText={t => {
								this.embed.title.name = t;
								this.forceUpdate();
							}}>Título:</NamedField>
						<NamedField
							value={this.embed.title.url}
							onChangeText={t => {
								this.embed.title.url = t;
								this.forceUpdate();
							}}>Link:</NamedField>
					</View>
					<View style={{marginBottom: 16}}>
						<Text style={{font: "bold 22px Whitney, \"Helvetica Neue\", Helvetica", color: "white"}}>
							Corpo
						</Text>
						<NamedField
							disabled={this.state.automated}
							value={this.embed.body}
							axLength={2048}
							multiline
							onChangeText={t => {
								this.embed.body = t;
								this.forceUpdate();
							}}>Descrição:</NamedField>
						<NamedField
							value={this.embed.thumbnail}
							onChangeText={t => {
								this.embed.thumbnail = t;
								this.forceUpdate();
							}}>Ícone:</NamedField>
						<View style={{marginLeft: 8, marginVertical: 8}}>
							<Text style={{font: "bold 18px Whitney, \"Helvetica Neue\", Helvetica", color: "white"}}>
								Imagens (separadas por vírgula)
							</Text>
							<NamedField
								value={this.embed.image.image.join(",")}
								onChangeText={t => {
									this.embed.image.image = t.replace(" ", "")
										.replace("\"", "")
										.split(",");
									this.getImageInfo(this.embed.image.image[0]);
									this.forceUpdate();
								}}>Padrão:</NamedField>
							<NamedField
								value={this.embed.image.join.join(",")}
								onChangeText={t => {
									this.embed.image.join = t.replace(" ", "")
										.replace("\"", "")
										.split(",");
									this.forceUpdate();
								}}>Boas-vindas:</NamedField>
							<NamedField
								value={this.embed.image.leave.join(",")}
								onChangeText={t => {
									this.embed.image.leave = t.replace(" ", "")
										.replace("\"", "")
										.split(",");
									this.forceUpdate();
								}}>Adeus:</NamedField>
						</View>
						<NamedField
							value={this.embed.color}
							maxLength={7}
							onChangeText={t => {
								if (t.length === 0) this.embed.color = "";
								else this.embed.color = "#" + t.replace("#", "");
								this.forceUpdate();
							}}>Cor:</NamedField>
					</View>
					<View style={{marginBottom: 16}}>
						<Text style={{font: "bold 22px Whitney, \"Helvetica Neue\", Helvetica", color: "white"}}>
							Rodapé
						</Text>
						<NamedField
							value={this.embed.footer.name}
							maxLength={2048}
							multiline
							onChangeText={t => {
								this.embed.footer.name = t;
								this.forceUpdate();
							}}>Título:</NamedField>
						<NamedField
							value={this.embed.footer.icon}
							onChangeText={t => {
								this.embed.footer.icon = t;
								this.forceUpdate();
							}}>Ícone:</NamedField>
					</View>
					<View style={{marginBottom: 16}}>
						<View style={{flexDirection: "row", alignItems: "center"}}>
							<Text
								style={{font: "bold 22px Whitney, \"Helvetica Neue\", Helvetica", color: "white"}}>
								Campos
							</Text>
							<Button
								style={{marginLeft: 8}}
								buttonStyle={{height: 25, width: 25, backgroundColor: Discord.success}}
								onPress={() => {
									if (this.embed.fields.length < 25) {
										this.embed.fields.push({
											name: "",
											value: "",
											inline: true
										});
										this.forceUpdate();
									}
								}}
								icon={
									<AntDesign
										name={"pluscircleo"}
										color={"white"}
										size={"1em"}
									/>
								}
							/>
						</View>
						{this.embed.fields.map((f, i) => (
							<View style={{marginLeft: 8, marginTop: 8}}>
								<View style={{flexDirection: "row", alignItems: "center"}}>
									<Text style={{
										font: "bold 18px Whitney, \"Helvetica Neue\", Helvetica",
										color: "white"
									}}>
										Campo {i + 1}
									</Text>
									<Button
										style={{marginLeft: 8}}
										buttonStyle={{height: 25, width: 25, backgroundColor: Discord.danger}}
										onPress={() => {
											this.embed.fields.splice(i, 1);
											this.forceUpdate();
										}}
										icon={
											<AntDesign
												name={"minuscircleo"}
												color={"white"}
												size={"1em"}
											/>
										}
									/>
								</View>
								<NamedField
									value={this.embed.fields[i].name}
									maxLength={256}
									onChangeText={t => {
										this.embed.fields[i].name = t;
										this.forceUpdate();
									}}>Título:</NamedField>
								<NamedField
									value={this.embed.fields[i].value}
									maxLength={1024}
									multiline
									onChangeText={t => {
										this.embed.fields[i].value = t;
										this.forceUpdate();
									}}>Descrição:</NamedField>
								<NamedRadio
									value={this.embed.fields[i].inline}
									onPress={() => {
										this.embed.fields[i].inline = !this.embed.fields[i].inline;
										this.forceUpdate();
									}}>Alinhado:</NamedRadio>
							</View>
						))}
					</View>
					<View style={{marginBottom: 16}}>
						<Text style={{font: "bold 22px Whitney, \"Helvetica Neue\", Helvetica", color: "white"}}>
							Extra
						</Text>
						<NamedRadio
							value={this.state.automated}
							onPress={() => {
								this.state.automated = !this.state.automated;
								this.embed.title.name = "";
								this.embed.body = "";
								this.forceUpdate();
							}}>Embed de boas-vindas/adeus</NamedRadio>
						<NamedRadio
							value={this.embed.showDate}
							onPress={() => {
								this.embed.showDate = !this.embed.showDate;
								this.forceUpdate();
							}}>Exibir data</NamedRadio>
					</View>
				</ScrollView>
				<View style={{
					bottom: 0,
					flexDirection: "row",
					width: "100%",
					justifyContent: "center",
					padding: 8
				}}>
					<Button
						title={"Copiar JSON"}
						style={{marginHorizontal: 8}}
						buttonStyle={{backgroundColor: Discord.success}}
						titleStyle={{fontSize: this.state.vertical ? "1em" : "1.5em"}}
						onPress={() => {
							this.embed.image.image = this.embed.image.image.filter(s => s !== "")
							this.embed.image.join = this.embed.image.join.filter(s => s !== "")
							this.embed.image.leave = this.embed.image.leave.filter(s => s !== "")

							Clipboard.setString(JSON.stringify(this.removeEmpty(this.removeEmpty(this.embed))));
							this.setState({
								toastVisible: true,
								toastMessage: "Copiado com sucesso!"
							})
							setTimeout(() => {
								this.setState({
									toastVisible: false,
									toastMessage: ""
								});
							}, 5000)
						}}
						icon={
							<AntDesign
								name={"save"}
								color={"white"}
								size={this.state.vertical ? "1.5em" : "2em"}
							/>
						}
					/>
					{this.state.vertical
						? <Button
							title={"Visualizar"}
							style={{marginHorizontal: 8}}
							titleStyle={{fontSize: this.state.vertical ? "1em" : "1.5em"}}
							buttonStyle={{backgroundColor: Discord.primary}}
							onPress={() => {
								this.menu.current.closeDrawer();
								this.setState({menuOpen: false});
							}}
							icon={
								<AntDesign
									name={"menu-fold"}
									color={"white"}
									size={this.state.vertical ? "1.5em" : "2em"}
								/>
							}
						/> : null}
					<Button
						title={"Limpar"}
						style={{marginHorizontal: 8}}
						buttonStyle={{backgroundColor: Discord.danger}}
						titleStyle={{fontSize: this.state.vertical ? "1em" : "1.5em"}}
						onPress={() => {
							this.embed = JSON.parse(JSON.stringify(embedModel));
							this.setState({
								toastVisible: true,
								toastMessage: "Campos limpos com sucesso!"
							}, this.forceUpdate)
							setTimeout(() => {
								this.setState({
									toastVisible: false,
									toastMessage: ""
								});
							}, 5000)
						}}
						icon={
							<AntDesign
								name={"delete"}
								color={"white"}
								size={this.state.vertical ? "1.5em" : "2em"}
							/>
						}
					/>
				</View>
			</KeyboardAvoidingView>
		);
	}

	embedPreview() {
		return (
			<View style={{flex: 5, margin: "5%", alignItems: "center", justifyContent: "center", pointerEvents: "none"}}>
				<View style={{
					backgroundColor: Discord.background,
					maxWidth: 520,
					paddingTop: 8,
					paddingRight: 16,
					paddingBottom: 16,
					paddingLeft: 16,
					borderRadius: 4,
					borderLeftColor: this.embed.color || Discord.background,
					borderLeftWidth: 4
				}}>
					<View style={{width: "100%", flexDirection: "row"}}>
						<View style={{
							maxWidth: 380,
							color: "#dcddde"
						}}>
							<View style={{marginTop: 8, flexDirection: "row", alignItems: "center"}}>
								<Image
									style={{
										width: 24,
										height: 24,
										borderRadius: "50%",
										marginRight: 8,
										display: this.embed.author?.icon ? "flex" : "none"
									}}
									source={{uri: this.embed.author?.icon}}
								/>
								<InlineUrl
									style={{
										font: "14px Whitney, \"Helvetica Neue\", Helvetica",
										color: "white",
										fontWeight: "600"
									}}
									linkColor={"white"}
									text={this.embed.author?.name}
									href={this.embed.author?.url}
								/>
							</View>
							<InlineUrl
								style={{
									font: "16px Whitney, \"Helvetica Neue\", Helvetica",
									color: "white",
									fontWeight: "600",
									marginTop: 8
								}}
								text={this.state.automated ? "Espaço reservado para título." : this.embed.title?.name}
								href={this.embed.title?.url}
							/>
							<View style={{marginVertical: -2}}>
								<Markdown rules={this.rules} markdownit={this.mdIgnore}>
									{this.state.automated ? "Espaço reservado para mensagem de boas-vindas/adeus." : this.embed.body.replace("```", "\n```\n")}
								</Markdown>
							</View>
							{this.makeFields()}
						</View>
						<View style={{marginLeft: "auto"}}>
							<Image
								style={{
									width: 80,
									height: 80,
									borderRadius: 4,
									marginTop: 8,
									marginLeft: 16,
									display: this.embed.thumbnail ? "flex" : "none"
								}}
								source={{uri: this.embed.thumbnail}}
							/>
						</View>
					</View>
					<Image
						style={{
							maxWidth: 520,
							resizeMode: "contain",
							width: this.state.imgSize.width,
							height: this.state.imgSize.height,
							borderRadius: 4,
							marginTop: 16,
							display: this.state?.img ? "flex" : "none"
						}}
						source={{uri: this.state?.img}}
					/>
					<View style={{marginTop: 8, flexDirection: "row", alignItems: "center"}}>
						<Image
							style={{
								width: 20,
								height: 20,
								borderRadius: "50%",
								marginRight: 8,
								display: this.embed.footer?.icon ? "flex" : "none"
							}}
							source={{uri: this.embed.footer?.icon}}
						/>
						<Text
							style={{
								font: "12px Whitney, \"Helvetica Neue\", Helvetica",
								color: "#dcddde"
							}}
						>
							{[this.embed.footer?.name, this.embed.showDate ? "Hoje às " + new Date().getHours().toString().padStart(2, "0") + ":" + new Date().getMinutes().toString().padStart(2, "0") : null].filter(s => s).join(" • ")}
						</Text>
					</View>
				</View>
			</View>
		);
	}

	componentDidMount() {
		this.resize();
		Dimensions.addEventListener("change", this.resize);
	}

	componentWillUnmount() {
		Dimensions.removeEventListener("change", this.resize);
	}

	render() {
		return (
			<SafeAreaView style={{
				flexDirection: "row",
				height: "100%",
				backgroundColor: Colors.containerDark
			}}>
				{
					this.state.vertical
						? <DrawerLayout
							ref={this.menu}
							drawerWidth={Dimensions.get("screen").width}
							drawerLockMode={this.state.menuOpen ? "locked-open" : "locked-closed"}
							renderNavigationView={() => this.embedConfig()}>
							<View style={{flex: 1}}>
								<Button
									style={{position: "absolute", top: 16, left: 8, zIndex: 99}}
									buttonStyle={{height: 50, width: 50, backgroundColor: "#0007"}}
									onPress={() => {
										this.menu.current.openDrawer();
										this.setState({menuOpen: true});
									}}
									icon={
										<AntDesign
											name={"menu-unfold"}
											color={"white"}
											size={"2em"}
										/>
									}
								/>
								{this.embedPreview()}
							</View>
						</DrawerLayout>
						: <View style={{flex: 1, flexDirection: "row"}}>
							{this.embedConfig()}
							{this.embedPreview()}
						</View>
				}
				<Snackbar
					style={{
						position: "absolute",
						bottom: 8,
						right: 16
					}}
					visible={this.state.toastVisible}
				>
					{this.state.toastMessage}
				</Snackbar>
			</SafeAreaView>
		);
	}
}
