import React from "react";
import {View} from "react-native";
import {Text} from "react-native-web";
import {Colors, Discord} from "../Styles";
import {CheckBox} from "react-native-elements";
import Spinner from "./Spinner";

export default class NamedRadio extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<View style={{marginLeft: 8, marginTop: 8, flexDirection: "row", alignItems: "center"}}>
				<Spinner
					value={this.props.value}
					onIncrease={this.props.onIncrease}
					onDecrease={this.props.onDecrease}
					onChange={this.props.onChange}
					style={{
						padding: 0,
						marginRight: 8
					}}
				/>
				<Text style={{
					font: "bold 16px Whitney, \"Helvetica Neue\", Helvetica",
					color: "white"
				}}>
					{this.props.children}
				</Text>
			</View>
		)
	}
}