import React from "react";
import {Image} from "react-native-elements";
import {View} from "react-native-web";

export default class GrowableImage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let interp = 1 - Math.min(Math.abs(Math.sin((2 * Math.PI) * this.props.delta)), this.props.maxDelta) * this.props.deltaStep;

		return (
			<View>
				<Image
					{...this.props}
					containerStyle={[this.props.containerStyle, {
						height: this.props.minHeight + (this.props.incHeight * interp) + this.props.unit,
						width: this.props.minWidth + (this.props.incWidth * interp) + this.props.unit,
					}]}
				/>
				{!this.props.delta && this.props.sub}
			</View>
		)
	}
}