import React from "react";
import {Button as ParentButton} from "react-native-elements";

export default class Button extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hover: false
		}
	}

	render() {
		return <ParentButton
			{...this.props}
			icon={this.state.hover ? this.props.hoverIcon : this.props.icon}
			onMouseEnter={() => this.setState({hover: true})}
			onMouseLeave={() => this.setState({hover: false})}
			buttonStyle={[this.props.buttonStyle, {
				backgroundColor: this.state.hover ? this.props.hoverColor : this.props.color
			}]}
			disabledStyle={[this.props.buttonStyle, {backgroundColor: this.props.disabledColor || "grey"}]}
		/>;
	}
}