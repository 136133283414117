import React from "react";
import {
	Animated,
	Dimensions,
	Easing,
	KeyboardAvoidingView,
	SafeAreaView,
	ScrollView,
	Text,
	TouchableOpacity,
	View
} from "react-native-web";
import {Colors, Styles} from "../Styles";
import Collapsible from "react-native-collapsible";
import Button from "../components/Button";
import {AntDesign} from "@expo/vector-icons";
import {Avatar, Image} from "react-native-elements";
import Profile from "./subPages/Profile";
import {calcSize, adjustName} from "../Helper";
import Ticket from "./subPages/Ticket";
import Loading from "./Loading";
import Kawaipon from "./subPages/Kawaipon";
import {Server as Socket} from "../App";
import * as Async from "async";
import Store from "./subPages/Store";

export default class Dashboard extends React.Component {
	static navigationOptions = {
		title: "Dashboard"
	};

	static path = "Dashboard";

	constructor(props) {
		super(props);

		this.resize = () => calcSize(this);
		this.menuAnimation = new Animated.Value(0);

		this.state = {
			menuIndex: -1,
			userData: JSON.parse(window.localStorage.getItem("user")),
			activeScreen: <Store/>,
			vertical: false,
			menuOpen: false,
			loaded: false
		};
	};

	componentDidMount() {
		let date = new Date();
		this.resize();
		Dimensions.addEventListener("change", this.resize);
		if (!this.state.userData || this.state.userData.sessionDate !== `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`) {
			window.localStorage.clear();
			window.open(`${window.location.origin}/Home`, "_self");
		}
		Socket.onopen = () => Socket.send(JSON.stringify({
			type: "validate",
			data: {
				token: this.state.userData.token
			}
		}));
		Socket.onmessage = ({data}) => {
			let d = JSON.parse(data);
			if (d.type === "validate") {
				if (d.code === 200) {
					Async.waterfall([
						done => {
							let payload = d.data;

							Object.assign(this.state.userData, payload.userData);

							window.localStorage.setItem("servers", JSON.stringify(payload.serverData));
							window.localStorage.setItem("user", JSON.stringify(this.state.userData));
							this.setState({
								serverData: payload.serverData,
								userData: this.state.userData
							});
							done();
						},
						done => {
							this.setState({loaded: true});
							done();
						}
					]);
				} else {
					window.localStorage.clear();
					window.open(`${window.location.origin}/Home`, "_self");
				}
			}
		};

		/*TODO Remover quando o AdSense for implementado*/
		this.state.userData.premium = true;
		this.adSize = this.state.userData.premium ? 0 : 14;
	}

	componentWillUnmount() {
		Dimensions.removeEventListener("change", this.resize);
	}

	toggleMenu() {
		if (this.state.menuOpen) {
			Animated.timing(this.menuAnimation, {
				toValue: 0,
				duration: 500,
				easing: Easing.out(Easing.quad)
			}).start();
		} else {
			Animated.timing(this.menuAnimation, {
				toValue: 100,
				duration: 500,
				easing: Easing.out(Easing.quad)
			}).start();
		}
		this.setState({menuOpen: !this.state.menuOpen});
	}

	sideMenu() {
		return (
			<View style={[Styles.dshLeftMenu, Styles.boxShadowRight]}>
				<ScrollView showsVerticalScrollIndicator={false}>
					<Button
						color={Colors.primaryDarker}
						hoverColor={Colors.primaryLighter}
						title={"Perfil"}
						buttonStyle={Styles.dshMenuButton}
						titleStyle={Styles.dshMenuButtonText}
						onPress={() => this.setState({menuIndex: this.state.menuIndex === 0 ? -1 : 0})}
					/>
					<Collapsible collapsed={this.state.menuIndex !== 0} style={Styles.dshMenuContent}>
						<Button
							color={Colors.secondary}
							hoverColor={Colors.secondaryLighter}
							title={"Resumo"}
							buttonStyle={Styles.dshMenuItem}
							titleStyle={Styles.dshText}
							onPress={() => {
								this.setState({activeScreen: <Profile/>});
								this.toggleMenu();
							}}
						/>
						<Button
							color={Colors.secondary}
							hoverColor={Colors.secondaryLighter}
							title={"Kawaipon"}
							buttonStyle={Styles.dshMenuItem}
							titleStyle={Styles.dshText}
							onPress={() => {
								this.setState({activeScreen: <Kawaipon/>});
								this.toggleMenu();
							}}
						/>
						<Button
							color={Colors.secondary}
							hoverColor={Colors.secondaryLighter}
							title={"Loja de cartas"}
							buttonStyle={Styles.dshMenuItem}
							titleStyle={Styles.dshText}
							onPress={() => {
								this.setState({activeScreen: <Store/>});
								this.toggleMenu();
							}}
						/>
					</Collapsible>

					<Button
						color={Colors.primaryDarker}
						hoverColor={Colors.primaryLighter}
						title={"Suporte"}
						buttonStyle={Styles.dshMenuButton}
						titleStyle={Styles.dshMenuButtonText}
						onPress={() => this.setState({menuIndex: this.state.menuIndex === 2 ? -1 : 2})}
					/>
					<Collapsible collapsed={this.state.menuIndex !== 2} style={Styles.dshMenuContent}>
						<Button
							color={Colors.secondary}
							hoverColor={Colors.secondaryLighter}
							//title={"Documentação"}
							title={"Em desenvolvimento"}
							buttonStyle={Styles.dshMenuItem}
							titleStyle={Styles.dshText}
							onPress={() => {
								this.toggleMenu();
							}}
						/>
						<Button
							color={Colors.secondary}
							hoverColor={Colors.secondaryLighter}
							title={"Enviar ticket"}
							buttonStyle={Styles.dshMenuItem}
							titleStyle={Styles.dshText}
							onPress={() => {
								this.setState({activeScreen: <Ticket/>});
								this.toggleMenu();
							}}
						/>
					</Collapsible>
					<Button
						color={Colors.primaryDarker}
						hoverColor={Colors.primaryLighter}
						//title={"Documentação"}
						title={"Shiro Canvas"}
						buttonStyle={Styles.dshMenuButton}
						titleStyle={Styles.dshMenuButtonText}
						onPress={() => {
							window.open(`${window.location.origin}/ShiroCanvas`, "_self");
						}}
					/>
				</ScrollView>
				<View style={Styles.dshFooter}>
					<Button
						color={Colors.primaryDark}
						hoverColor={Colors.primaryDarker}
						icon={
							<AntDesign
								name={"logout"}
								color={Colors.pinkText}
								size={"3vmax"}
							/>
						}
						hoverIcon={
							<AntDesign
								name={"logout"}
								color={Colors.pinkText}
								size={"3vmax"}
							/>
						}
						title={"Sair"}
						buttonStyle={{
							borderRadius: 0,
							height: "6vh",
							padding: 0
						}}
						titleStyle={{
							fontFamily: "Doreking",
							color: Colors.pinkText,
							fontSize: "1.5vmax",
							marginLeft: "0.5vmax"
						}}
						onPress={() => {
							window.localStorage.clear();
							window.open(`${window.location.origin}/Home`, "_self");
						}}
					/>
				</View>
			</View>
		);
	}

	getMenu() {
		const menuPos = this.menuAnimation.interpolate({
			inputRange: [0, 100],
			outputRange: ["-50vw", "0vw"]
		});
		const shade = this.menuAnimation.interpolate({
			inputRange: [0, 100],
			outputRange: ["#0000", "#000A"]
		});

		if (this.state.vertical) return (
			<View style={[Styles.boxShadowUp, {
				flexDirection: "row",
				height: "90vh",
				zIndex: this.state.menuOpen ? 999 : -999
			}]}>
				<Animated.View
					style={{
						backgroundColor: shade,
						width: "100vw",
						height: "90vh",
						position: "absolute"
					}}>
					<TouchableOpacity
						style={{bottom: "10vh", height: "100vh", width: "100vw"}}
						onPress={() => this.toggleMenu()}
					/>
				</Animated.View>
				<Animated.View style={{left: menuPos, width: "50vw", position: "absolute"}}>
					{this.sideMenu()}
				</Animated.View>
			</View>
		);
		else return (
			<View style={[Styles.boxShadowUp, {flexDirection: "row", height: "90vh", width: "15vw"}]}>
				{this.sideMenu()}
			</View>
		);
	}

	renderAvatar() {
		if (this.state.vertical) return (
			<Button color={"#0009"}
					hoverColor={"#1119"}
					icon={
						<AntDesign
							name={this.state.menuOpen ? "menu-unfold" : "menu-fold"}
							color={Colors.pinkText}
							size={"5vh"}
						/>
					}
					hoverIcon={
						<AntDesign
							name={this.state.menuOpen ? "menu-unfold" : "menu-fold"}
							color={Colors.pinkText}
							size={"5vh"}
						/>
					}
					buttonStyle={{height: "10vh", width: "10vh", borderRadius: 0}}
					containerStyle={{height: "10vh", width: "10vh", borderRadius: 0}}
					onPress={() => this.toggleMenu()}
			/>
		);
		else return (
			<Avatar
				title={this.state.userData.name.charAt(0)}
				source={{uri: this.state.userData.avatar}}
				containerStyle={{height: "10vh", width: "10vh"}}
				titleStyle={Styles.dshAvatarChar}
				placeholderStyle={Styles.dshAvatarChar}
			/>
		);
	}

	render() {
		if (this.state.loaded) {
			let highest = {level: 0};
			for (const p of this.state.userData.profiles) {
				if (p.level >= highest.level) {
					highest = p;
				}
			}

			return (
				<SafeAreaView style={{height: "100%", backgroundColor: Colors.containerDark}}>
					<KeyboardAvoidingView>
						<Image source={{uri: highest.bg}} style={{flex: 1, height: "100vh"}}>
							<View style={Styles.dshHeader}>
								{this.renderAvatar()}
								<View style={Styles.dshProfileBox}>
									<Text
										style={Styles.dshProfileName}>{adjustName(this.state.userData.name, 32)}</Text>
									<Text style={Styles.dshProfileDetails}>Level: {highest.level}</Text>
									<Text
										style={Styles.dshProfileDetails}>Rank: {this.state.userData.rank}.º</Text>
								</View>
							</View>
							<View style={[Styles.boxShadowRightUp, {
								flexDirection: "row",
								height: this.state.vertical ? (90 - this.adSize) + "vh" : "90vh",
								width: this.state.vertical ? "100vw" : (100 - this.adSize) + "vw"
							}]}>
								{this.getMenu()}
								<SafeAreaView style={{
									width: this.state.vertical ? "100vw" : (85 - this.adSize) + "vw",
									height: "100%"
								}}>
									<ScrollView showsVerticalScrollIndicator={false} style={[Styles.dshBody]}>
										{this.state.activeScreen}
									</ScrollView>
								</SafeAreaView>
								{
									!this.state.vertical && !this.state.userData.premium ?
										<View style={{
											backgroundColor: "red",
											width: this.adSize + "vw",
											marginLeft: "1vw"
										}}>
											{/*TODO Ad area*/}
										</View> : null
								}
							</View>
							{
								this.state.vertical && !this.state.userData.premium ?
									<View style={{
										backgroundColor: "red",
										height: this.adSize + "vh",
										marginTop: "1vh",
										zIndex: -1
									}}>
										{/*TODO Ad area (mobile)*/}
									</View> : null
							}
						</Image>
					</KeyboardAvoidingView>
				</SafeAreaView>
			);
		} else
			return (
				<SafeAreaView style={{flex: 1}}>
					<Loading/>
				</SafeAreaView>
			);
	}
}
