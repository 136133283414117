import {Dimensions} from "react-native-web";

export function calcSize(screen) {
	let dim = Dimensions.get("window");
	screen.setState({
		vertical: dim.height >= dim.width
	});
}

export async function fetchWithTimeout(url, options, timeout = 5000) {
	return Promise.race([
		fetch(url, options),
		new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
	]);
}

export function shorten(text, size) {
	if (text.length > size) return text.substring(0, size) + "...";
	else return text;
}

export function adjustName(name, maxLength) {
	if (name.length > maxLength) return name.substring(0, maxLength - 1) + "...";
	else return name;
}

export function toTitleCase(str) {
	return str.replace(
		/\w\S*/g,
		function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
}

export function getColor(rarity) {
	switch (rarity) {
		default:
		case 1:
		case "COMMON":
			return "#FFFFFF";
		case 2:
		case "UNCOMMON":
			return "#03BB85";
		case 3:
		case "RARE":
			return "#70D1F4";
		case 4:
		case "ULTRA_RARE":
			return "#9966CC";
		case 5:
		case "LEGENDARY":
			return "#DC9018";
		case 10:
		case "ULTIMATE":
			return "#D400AA";
	}
}

export function n(text, times) {
	let out = "";
	for (let i = 0; i < times; i++) {
		out += text;
	}

	return out;
}

export function compare(a, b) {
	return a > b ? 1 : b > a ? -1 : 0;
}

export function chunkify(list, chunkSize) {
	let overflow = list.length % chunkSize;
	let chunks = [];

	for (let i = 0; i < (list.length - overflow) / chunkSize; i++) {
		chunks.push(list.slice(i * chunkSize, (i * chunkSize) + chunkSize));
	}

	chunks.push(list.slice(list.length - overflow, list.length));

	return chunks;
}

export function clamp(val, min, max) {
	return Math.max(min, Math.min(val, max));
}