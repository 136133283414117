import React from "react";
import {Button as ParentButton} from "react-native-elements";
import {Colors, Styles} from "../Styles";
import {Text, View} from "react-native-web";

export default class TitledText extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <View style={{flexDirection: "row", flex: 1}}>
				<Text style={[this.props.titleStyle, {flex: 3}]}>{this.props.title}</Text>
				<Text style={[this.props.textStyle, {flex: 7}]}>{this.props.text}</Text>
			</View>
	}
}