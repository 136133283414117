import React from "react";
import {adjustName} from "../Helper";
import Loading from "./Loading";
import {
    Dimensions,
    Easing,
    FlatList,
    Image,
    ImageBackground,
    SafeAreaView,
    ScrollView,
    Text,
    TextInput,
    TouchableWithoutFeedback,
    View
} from "react-native-web";
import {Colors} from "../Styles";
import ReconnectingWebsocket from "reconnecting-websocket";
import {Animated} from "react-native";
import HsvColorPicker from "react-native-hsv-color-picker";
import isEmpty from "react-native-web/dist/vendor/react-native/isEmpty";
import {Button as PaperButton} from "react-native-paper";
import {AntDesign} from "@expo/vector-icons";
import Button from "../components/Button";
import ConfirmDialog from "../components/ConfirmDialog";
import {BlurView} from "expo-blur";
import Popup from "../components/Popup";
import {PanGestureHandler} from "react-native-gesture-handler";
import Jimp from "jimp";
import {Buffer} from "buffer";
import colorConvert from "color-convert";

export const Canvas = new ReconnectingWebsocket("wss://socket.shirojbot.site/canvas");

export default class ShiroCanvas extends React.Component {
    static navigationOptions = {
        title: "ShiroCanvas"
    };

    static path = "ShiroCanvas";

    constructor(props) {
        super(props);

        this.highlight = evt => this.setState({
            highlightPos: {
                x: Math.floor((evt.pageX + this.state.scrollOffset.x) / this.state.zoom) * (this.state.zoom),
                y: Math.floor((evt.pageY + this.state.scrollOffset.y) / this.state.zoom) * (this.state.zoom)
            }
        });
        this.mapClick = evt => {
            let height = Dimensions.get("window").height;
            let width = Dimensions.get("window").width;

            let scrollY = evt.nativeEvent.locationY * (this.mapSize * this.state.zoom) / (Math.max(height, width) * 0.15) - (height / 2);
            let scrollX = evt.nativeEvent.locationX * (this.mapSize * this.state.zoom) / (Math.max(height, width) * 0.15) - (width / 2);

            this.setState({
                scrollOffset: {
                    y: Math.max(0, Math.min(scrollY, (this.mapSize * this.state.zoom) - height)),
                    x: Math.max(0, Math.min(scrollX, (this.mapSize * this.state.zoom) - width))
                }
            }, () => {
                this.verticalScroll.current.scrollTo({
                    y: this.state.scrollOffset.y,
                    animated: true
                });
                this.horizontalScroll.current.scrollTo({
                    x: this.state.scrollOffset.x,
                    animated: true
                });
            })
        }
        this.hueChange = ({hue}) => {
            this.setState({
                hsv: {
                    hue: hue,
                    sat: this.state.hsv.sat,
                    val: this.state.hsv.val
                }
            });
        }
        this.satChange = ({saturation, value}) => {
            this.setState({
                hsv: {
                    hue: this.state.hsv.hue,
                    sat: saturation,
                    val: value
                }
            });
        }
        this.togglePicker = () => {
            if (this.state.pickerVisible) {
                Animated.timing(this.pickerAnimation, {
                    toValue: 0,
                    duration: 500,
                    easing: Easing.out(Easing.quad)
                }).start();
            } else {
                Animated.timing(this.pickerAnimation, {
                    toValue: 100,
                    duration: 500,
                    easing: Easing.out(Easing.quad)
                }).start();
            }
            this.setState({pickerVisible: !this.state.pickerVisible});
        }

        this.delta = new Animated.Value(100);
        this.pickerAnimation = new Animated.Value(0);
        this.verticalScroll = React.createRef();
        this.horizontalScroll = React.createRef();
        this.map = React.createRef();
        this.picker = React.createRef();
        this.chatInput = React.createRef();
        this.game = React.createRef();
        this.jimp = null;
        this.fired = false;
        this.mapSize = 0;

        this.state = {
            userData: JSON.parse(window.localStorage.getItem("user")),
            connected: false,
            canvas: null,
            highlightPos: {x: 0, y: 0},
            scrollOffset: {x: 0, y: 0},
            zoom: 16,
            color: "#ffffff",
            hsv: {hue: 0, sat: 0, val: 0},
            pickerVisible: false,
            cooldown: 0,
            mapToggle: true,
            helpToggle: true,
            chatToggle: true,
            showExit: false,
            showMotd: true,
            focused: false,
            chat: [{id: 0, sender: "Sistema", message: "Bem-vindo(a) ao Shiro Canvas!"}],
            message: "",
            latestMessage: 0,
            announce: true,
            justDragging: false,
            leftClick: false,
            mapHover: false
        };
    };

    componentDidMount() {
        if (!this.state.userData) {
            window.localStorage.clear();
            window.open(`${window.location.origin}/Home`, "_self");
        }
        Canvas.onopen = () => {
            if (this.state.announce) {
                Canvas.send(JSON.stringify({
                    type: "chat",
                    token: this.state.userData.token,
                    content: {
                        id: -Date.now(),
                        sender: "Sistema",
                        message: `${this.state.userData.name} entrou no canvas!`
                    },
                    info: {
                        size: this.mapSize
                    }
                }));
            }
            this.setState({connected: true, announce: false});
        };
        Canvas.onclose = () => {
            this.setState({connected: false});
            console.log("Reconectando");
        };
        Canvas.onmessage = ({data}) => {
            let payload = JSON.parse(data);
            switch (payload.type) {
                case "canvas_init":
                    Jimp.read(Buffer.from(payload.content, 'base64'))
                        .then(jimp => {
                            this.jimp = jimp;
                            return jimp.getBase64Async(Jimp.MIME_PNG);
                        })
                        .then(img => {
                            this.setState({canvas: img});
                            this.mapSize = payload.info.size;
                        });
                    break;
                case "canvas_update":
                    if (payload.info.size !== this.mapSize) window.location.reload(true);
                    this.jimp.setPixelColor(Jimp.cssColorToHex(payload.color), payload.x, payload.y, () => {
                        this.jimp.getBase64Async(Jimp.MIME_PNG)
                            .then(img => {
                                this.setState({canvas: img});
                            })
                    });
                    break;
                case "chat":
                    this.setState({
                        chat: [payload.content, ...this.state.chat]
                    });
                    break;
            }
        };

        this.handleSC = evt => {
            if (!this.fired) {
                switch (evt.key.toLowerCase()) {
                    case "+":
                        if (this.state.zoom < 32) {
                            let newZoom = this.state.zoom + 1;
                            let newOffset = {
                                x: Math.max(0,
                                    Math.min(
                                        (newZoom * this.state.scrollOffset.x / this.state.zoom) + ((Dimensions.get("window").width / this.state.zoom) / 2), (this.mapSize * newZoom) - Dimensions.get("window").width
                                    )
                                ),
                                y: Math.max(0,
                                    Math.min(
                                        (newZoom * this.state.scrollOffset.y / this.state.zoom) + ((Dimensions.get("window").height / this.state.zoom) / 2), (this.mapSize * newZoom) - Dimensions.get("window").height
                                    )
                                )
                            }
                            this.setState({
                                zoom: newZoom,
                                highlightPos: {x: 0, y: 0},
                                scrollOffset: newOffset,
                            }, () => {
                                this.horizontalScroll.current.scrollTo({
                                    x: this.state.scrollOffset.x,
                                    animated: false
                                });
                                this.verticalScroll.current.scrollTo({
                                    y: this.state.scrollOffset.y,
                                    animated: false
                                });
                            });
                        }
                        break;
                    case "-":
                        if (this.state.zoom > 2) {
                            let newZoom = this.state.zoom - 1;
                            let newOffset = {
                                x: Math.max(0,
                                    Math.min(
                                        (newZoom * this.state.scrollOffset.x / this.state.zoom) + ((Dimensions.get("window").width / this.state.zoom) * -1 / 2), (this.mapSize * newZoom) - Dimensions.get("window").width
                                    )
                                ),
                                y: Math.max(0,
                                    Math.min(
                                        (newZoom * this.state.scrollOffset.y / this.state.zoom) + ((Dimensions.get("window").height / this.state.zoom) * -1 / 2), (this.mapSize * newZoom) - Dimensions.get("window").height
                                    )
                                )
                            }
                            this.setState({
                                zoom: newZoom,
                                highlightPos: {x: 0, y: 0},
                                scrollOffset: newOffset,
                            }, () => {
                                this.horizontalScroll.current.scrollTo({
                                    x: this.state.scrollOffset.x,
                                    animated: false
                                });
                                this.verticalScroll.current.scrollTo({
                                    y: this.state.scrollOffset.y,
                                    animated: false
                                });
                            });
                        }
                        break;
                    case "m":
                        this.setState({mapToggle: !this.state.mapToggle});
                        break;
                    case "h":
                        this.setState({helpToggle: !this.state.helpToggle});
                        break;
                    case "c":
                        this.setState({chatToggle: !this.state.chatToggle});
                        break;
                    case "escape":
                        this.setState({showExit: !this.state.showExit});
                        break;
                    case "tab":
                        evt.preventDefault();
                        break;
                    case " ":
                        evt.preventDefault();
                        this.setState({color: this.getPixelHex()});
                        break;
                    default:
                        evt.preventDefault();
                }
                this.fired = true;
            }
        };
        this.releaseSC = () => {
            if (this.fired) this.fired = false;
        };
        this.requestFocus = () => {
            this.setState({focused: false});
        };
        this.handleMouse = evt => {
            if (evt.button === 2) {
                evt.preventDefault();
                this.setState({color: this.getPixelHex()});
            }
            this.setState({leftClick: evt.button === 0});
        }
        this.releaseMouse = () => {
            this.setState({leftClick: false});
        }
        this.handleMenu = evt => evt.preventDefault();

        window.addEventListener("keydown", this.handleSC);
        window.addEventListener("keyup", this.releaseSC);
        window.addEventListener("mousedown", this.handleMouse);
        window.addEventListener("mouseup", this.releaseMouse);
        window.addEventListener("contextmenu", this.handleMenu);
        window.addEventListener("blur", this.requestFocus);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleSC);
        window.removeEventListener("keyup", this.releaseSC);
        window.removeEventListener("mousedown", this.handleMouse);
        window.removeEventListener("mouseup", this.releaseMouse);
        window.removeEventListener("contextmenu", this.handleMenu);
        window.removeEventListener("blur", this.requestFocus);
    }

    putPixel() {
        this.highlightOpacity();
        Canvas.send(JSON.stringify({
            type: "canvas",
            token: this.state.userData.token,
            content: {
                pixel: {
                    x: this.state.highlightPos.x / this.state.zoom,
                    y: this.state.highlightPos.y / this.state.zoom,
                    color: this.state.color
                }
            },
            info: {
                size: this.mapSize
            }
        }));
        this.setState({cooldown: 3});
        let countdown = setInterval(() => {
            this.setState({cooldown: Math.max(0, this.state.cooldown - 1)});
            if (this.state.cooldown <= 0) {
                clearInterval(countdown);
            }
        }, 1000);
    }

    highlightOpacity() {
        this.delta.setValue(0);
        Animated.timing(this.delta, {
            toValue: 100,
            duration: 300,
            easing: Easing.linear
        }).start();
    }

    getPixelHSV() {
        if (this.jimp) {
            let rgb = this.jimp.getPixelColor(this.state.highlightPos.x / this.state.zoom, this.state.highlightPos.y / this.state.zoom);
            let hsv = colorConvert.rgb.hsv((rgb & 0xFF000000) >>> 24, (rgb & 0xFF0000) >>> 16, (rgb & 0xFF00) >>> 8);

            return `H: ${hsv[0]} | S: ${hsv[1]} | V: ${hsv[2]}`;
        }

        return "H: ? | S: ? | V: ?";
    }

    getPixelRGB() {
        if (this.jimp) {
            let rgb = this.jimp.getPixelColor(this.state.highlightPos.x / this.state.zoom, this.state.highlightPos.y / this.state.zoom);

            return `rgb(${(rgb & 0xFF000000) >>> 24},${(rgb & 0xFF0000) >>> 16},${(rgb & 0xFF00) >>> 8})`
        }

        return "rgb(0,0,0)";
    }

    getPixelHex() {
        if (this.jimp) {
            let rgb = this.jimp.getPixelColor(this.state.highlightPos.x / this.state.zoom, this.state.highlightPos.y / this.state.zoom);
            return "#" + colorConvert.rgb.hex((rgb & 0xFF000000) >>> 24, (rgb & 0xFF0000) >>> 16, (rgb & 0xFF00) >>> 8);
        }

        return "#000000";
    }

    getPixelPosition() {
        return `X: ${this.state.highlightPos.x / this.state.zoom - Math.floor(this.mapSize / 2)} (${this.state.highlightPos.x / this.state.zoom}) | Y: ${Math.floor(this.mapSize / 2) - this.state.highlightPos.y / this.state.zoom} (${this.state.highlightPos.y / this.state.zoom})`;
    }

    makeOutline(text, containerStyles, textStyles) {
        const offsets = [
            {x: -1, y: 0, hasColor: false},
            {x: -1, y: 1, hasColor: false},
            {x: 0, y: 1, hasColor: false},
            {x: 1, y: 1, hasColor: false},
            {x: 1, y: 0, hasColor: false},
            {x: 1, y: -1, hasColor: false},
            {x: 0, y: -1, hasColor: false},
            {x: -1, y: -1, hasColor: true}
        ];

        return (<View style={containerStyles}>
            {offsets.map(o => (
                o.hasColor ?
                    <Text style={[textStyles, {
                        position: "absolute",
                        textShadowColor: "black",
                        textShadowOffset: {width: o.x, height: o.y}
                    }]}>
                        {text}
                    </Text> :
                    <Text style={[textStyles, {
                        position: "absolute",
                        textShadowColor: "black",
                        textShadowOffset: {width: o.x, height: o.y},
                        color: "transparent"
                    }]}>
                        {text}
                    </Text>
            ))}
        </View>);
    }

    renderUI() {
        const pickerPos = this.pickerAnimation.interpolate({
            inputRange: [0, 100],
            outputRange: ["-20vmax", "0vmax"]
        });

        return (
            <View
                pointerEvents={"box-none"}
                style={{
                    alignItems: "flex-end",
                    position: "fixed",
                    height: "100vh",
                    width: "100vw",
                    zIndex: 999
                }}
            >
                {
                    this.state.focused ? null :
                        <BlurView
                            style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                height: "100vh",
                                width: "100vw",
                                zIndex: 1000,
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            tint={"dark"}
                            intensity={75}
                            onMouseUp={() => {
                                this.setState({focused: true});
                            }}
                        >
                            <Text style={{color: "white", fontWeight: "bold", fontSize: "5vw", fontFamily: "Ubuntu"}}>
                                Clique para recuperar o foco
                            </Text>
                        </BlurView>
                }
                {
                    this.state.helpToggle &&
                    <View style={{backgroundColor: "#00000055", alignSelf: "flex-start", position: "fixed"}}>
                        <PaperButton
                            compact
                            color={Colors.primary}
                            onPress={() => this.setState({mapToggle: !this.state.mapToggle})}
                        >
                            <AntDesign
                                name={"picture"}
                                color={Colors.primary}
                                size={20}
                            />
                        </PaperButton>
                        <PaperButton
                            compact
                            color={Colors.primary}
                            onPress={() => this.setState({chatToggle: !this.state.chatToggle})}
                        >
                            <AntDesign
                                name={"team"}
                                color={Colors.primary}
                                size={20}
                            />
                        </PaperButton>
                        <PaperButton
                            compact
                            color={Colors.primary}
                            onPress={() => this.setState({showExit: true})}
                        >
                            <AntDesign
                                name={"poweroff"}
                                color={Colors.primary}
                                size={20}
                            />
                        </PaperButton>
                    </View>
                }
                {
                    this.state.mapToggle &&
                    <View style={{flexDirection: "row", height: "15vmax"}}>
                        <View style={{
                            borderRadius: "0.5vmin",
                            backgroundColor: "#00000055",
                            marginHorizontal: "2%",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Button
                                icon={
                                    <AntDesign
                                        name={"plussquareo"}
                                        color={"#ffffff55"}
                                        size={20}
                                    />
                                }
                                hoverIcon={
                                    <AntDesign
                                        name={"plussquare"}
                                        color={"#ffffff55"}
                                        size={20}
                                    />
                                }
                                onPress={() => {
                                    if (this.state.zoom < 32) {
                                        let newZoom = this.state.zoom + 1;
                                        let newOffset = {
                                            x: Math.max(0,
                                                Math.min(
                                                    (newZoom * this.state.scrollOffset.x / this.state.zoom) + ((Dimensions.get("window").width / this.state.zoom) / 2), (this.mapSize * newZoom) - Dimensions.get("window").width
                                                )
                                            ),
                                            y: Math.max(0,
                                                Math.min(
                                                    (newZoom * this.state.scrollOffset.y / this.state.zoom) + ((Dimensions.get("window").height / this.state.zoom) / 2), (this.mapSize * newZoom) - Dimensions.get("window").height
                                                )
                                            )
                                        }
                                        this.setState({
                                            zoom: newZoom,
                                            highlightPos: {x: 0, y: 0},
                                            scrollOffset: newOffset,
                                        }, () => {
                                            this.horizontalScroll.current.scrollTo({
                                                x: this.state.scrollOffset.x,
                                                animated: false
                                            });
                                            this.verticalScroll.current.scrollTo({
                                                y: this.state.scrollOffset.y,
                                                animated: false
                                            });
                                        })
                                    }
                                }}
                            />
                            <Button
                                icon={
                                    <AntDesign
                                        name={"minussquareo"}
                                        color={"#ffffff55"}
                                        size={20}
                                    />
                                }
                                hoverIcon={
                                    <AntDesign
                                        name={"minussquare"}
                                        color={"#ffffff55"}
                                        size={20}
                                    />
                                }
                                onPress={() => {
                                    if (this.state.zoom > 2) {
                                        let newZoom = this.state.zoom - 1;
                                        let newOffset = {
                                            x: Math.max(0,
                                                Math.min(
                                                    (newZoom * this.state.scrollOffset.x / this.state.zoom) + ((Dimensions.get("window").width / this.state.zoom) * -1 / 2), (this.mapSize * newZoom) - Dimensions.get("window").width
                                                )
                                            ),
                                            y: Math.max(0,
                                                Math.min(
                                                    (newZoom * this.state.scrollOffset.y / this.state.zoom) + ((Dimensions.get("window").height / this.state.zoom) * -1 / 2), (this.mapSize * newZoom) - Dimensions.get("window").height
                                                )
                                            )
                                        }
                                        this.setState({
                                            zoom: newZoom,
                                            highlightPos: {x: 0, y: 0},
                                            scrollOffset: newOffset,
                                        }, () => {
                                            this.horizontalScroll.current.scrollTo({
                                                x: this.state.scrollOffset.x,
                                                animated: false
                                            });
                                            this.verticalScroll.current.scrollTo({
                                                y: this.state.scrollOffset.y,
                                                animated: false
                                            });
                                        })
                                    }
                                }}
                            />
                            <Text style={{color: "white", fontFamily: "Ubuntu"}}>{this.state.zoom}</Text>
                        </View>
                        <TouchableWithoutFeedback
                            onPress={this.mapClick}
                            onMouseEnter={() => this.setState({mapHover: true})}
                            onMouseLeave={() => this.setState({mapHover: false})}
                        >
                            <View style={{backgroundColor: "#00000055"}}>
                                <ImageBackground
                                    ref={this.map}
                                    style={{opacity: this.state.mapHover ? 1 : 0.5, height: "15vmax", width: "15vmax"}}
                                    source={{uri: this.state.canvas}}
                                    resizeMode={"cover"}
                                >
                                    <View
                                        pointerEvents={"none"}
                                        style={{
                                            top: (this.state.scrollOffset.y * 100 / (this.mapSize * this.state.zoom)) + "%",
                                            left: (this.state.scrollOffset.x * 100 / (this.mapSize * this.state.zoom)) + "%",
                                            height: (Dimensions.get("window").height * 100 / (this.mapSize * this.state.zoom)) + "%",
                                            width: (Dimensions.get("window").width * 100 / (this.mapSize * this.state.zoom)) + "%",
                                            backgroundColor: "#ffffff33",
                                            borderWidth: "0.1vmin",
                                            borderColor: "white"
                                        }}
                                    />
                                </ImageBackground>
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                }
                <View pointerEvents="none" style={{width: "15vmax", alignItems: "flex-end"}}>
                    {this.makeOutline(
                        this.getPixelPosition(),
                        {
                            height: 20,
                            width: "100%"
                        },
                        {
                            color: "white",
                            right: 0,
                            fontWeight: "400"
                        }
                    )}
                    {this.makeOutline(
                        this.getPixelHSV(),
                        {
                            height: 20,
                            width: "100%"
                        },
                        {
                            color: this.getPixelRGB(),
                            right: 0,
                            fontWeight: "400"
                        }
                    )}
                </View>
                <Animated.View pointerEvents={"box-none"}
                               style={{
                                   top: "1%",
                                   right: pickerPos,
                                   flexDirection: "row",
                                   alignItems: "flex-start"
                               }}>
                    <View
                        style={{
                            borderTopLeftRadius: "0.5vmin",
                            borderBottomLeftRadius: "0.5vmin",
                            backgroundColor: "#00000055"
                        }}
                    >
                        <PaperButton
                            compact
                            color={Colors.primary}
                            onPress={this.togglePicker}
                        >
                            <AntDesign
                                name={"caret" + (this.state.pickerVisible ? "right" : "left")}
                                color={Colors.primary}
                                size={10}
                            />
                        </PaperButton>
                    </View>
                    <View
                        style={{
                            borderRadius: "0.5vmin",
                            borderTopLeftRadius: 0,
                            backgroundColor: "#00000055",
                            width: "20vmax"
                        }}
                    >
                        <Text style={{margin: "10px", color: "white", fontFamily: "Ubuntu"}}>Dica: O botão direito copia
                            a cor do pixel atual.
                        </Text>
                        <HsvColorPicker
                            ref={this.picker}
                            huePickerHue={this.state.hsv.hue}
                            onHuePickerDragMove={this.hueChange}
                            onHuePickerPress={this.hueChange}
                            satValPickerHue={this.state.hsv.hue}
                            satValPickerSaturation={this.state.hsv.sat}
                            satValPickerValue={this.state.hsv.val}
                            onSatValPickerDragMove={this.satChange}
                            onSatValPickerPress={this.satChange}
                            huePickerBarHeight={
                                Math.min(Dimensions.get("window").height, Dimensions.get("window").width) * 0.20
                            }
                            huePickerBarWidth={
                                Math.max(Dimensions.get("window").width, Dimensions.get("window").height) * 0.01
                            }
                            huePickerSliderSize={
                                Math.max(Dimensions.get("window").width, Dimensions.get("window").height) * 0.02
                            }
                            satValPickerSize={
                                Math.min(Dimensions.get("window").height, Dimensions.get("window").width) * 0.20
                            }
                            satValPickerSliderSize={
                                Math.max(Dimensions.get("window").width, Dimensions.get("window").height) * 0.02
                            }
                        />
                        <View style={{flexDirection: "row", alignItems: "center", padding: "2%"}}>
                            <View style={{flex: 1, alignItems: "center"}}>
                                <Text style={{color: "white", fontFamily: "Ubuntu"}}>H</Text>
                                <TextInput
                                    value={Math.round(this.state.hsv.hue)}
                                    maxLength={3}
                                    style={{
                                        height: "2em",
                                        width: "3em",
                                        backgroundColor: "#ffffff55",
                                        borderWidth: "0.1vmin",
                                        borderRadius: "10%",
                                        textAlign: "center",
                                        fontFamily: "Ubuntu"
                                    }}
                                    onChangeText={t => {
                                        if (!isNaN(parseInt(t))) {
                                            this.setState({
                                                hsv: {
                                                    hue: parseInt(t),
                                                    sat: this.state.hsv.sat,
                                                    val: this.state.hsv.val
                                                }
                                            })
                                        } else if (isEmpty(t)) {
                                            this.setState({
                                                hsv: {
                                                    hue: 0,
                                                    sat: this.state.hsv.sat,
                                                    val: this.state.hsv.val
                                                }
                                            })
                                        }
                                    }}
                                />
                            </View>
                            <View style={{flex: 1, alignItems: "center"}}>
                                <Text style={{color: "white", fontFamily: "Ubuntu"}}>S</Text>
                                <TextInput
                                    value={Math.round(this.state.hsv.sat * 100)}
                                    maxLength={3}
                                    style={{
                                        height: "2em",
                                        width: "3em",
                                        backgroundColor: "#ffffff55",
                                        borderWidth: "0.1vmin",
                                        borderRadius: "10%",
                                        textAlign: "center",
                                        fontFamily: "Ubuntu"
                                    }}
                                    onChangeText={t => {
                                        if (!isNaN(parseInt(t))) {
                                            this.setState({
                                                hsv: {
                                                    hue: this.state.hsv.hue,
                                                    sat: parseInt(t) / 100,
                                                    val: this.state.hsv.val
                                                }
                                            })
                                        } else if (isEmpty(t)) {
                                            this.setState({
                                                hsv: {
                                                    hue: this.state.hsv.hue,
                                                    sat: 0,
                                                    val: this.state.hsv.val
                                                }
                                            })
                                        }
                                    }}
                                />
                            </View>
                            <View style={{flex: 1, alignItems: "center"}}>
                                <Text style={{color: "white", fontFamily: "Ubuntu"}}>V</Text>
                                <TextInput
                                    value={Math.round(this.state.hsv.val * 100)}
                                    maxLength={3}
                                    style={{
                                        height: "2em",
                                        width: "3em",
                                        backgroundColor: "#ffffff55",
                                        borderWidth: "0.1vmin",
                                        borderRadius: "10%",
                                        textAlign: "center",
                                        fontFamily: "Ubuntu"
                                    }}
                                    onChangeText={t => {
                                        if (!isNaN(parseInt(t))) {
                                            this.setState({
                                                hsv: {
                                                    hue: this.state.hsv.hue,
                                                    sat: this.state.hsv.sat,
                                                    val: parseInt(t) / 100
                                                }
                                            })
                                        } else if (isEmpty(t)) {
                                            this.setState({
                                                hsv: {
                                                    hue: this.state.hsv.hue,
                                                    sat: this.state.hsv.sat,
                                                    val: 0
                                                }
                                            })
                                        }
                                    }}
                                />
                            </View>
                        </View>
                        <PaperButton
                            color={Colors.primary}
                            onPress={() => {
                                this.setState({color: this.picker.current.getCurrentColor()});
                            }}
                        >
                            Aplicar
                        </PaperButton>
                    </View>
                    {
                        this.state.chatToggle &&
                        <View
                            pointerEvents={"box-none"}
                            style={{
                                position: "fixed",
                                bottom: 0,
                                left: 0,
                                height: "10em",
                                width: "20em",
                                backgroundColor: "#00000033",
                                borderTopLeftRadius: "0.5vmin",
                                borderTopRightRadius: "0.5vmin",
                                padding: "0.5%"
                            }}
                        >
                            <FlatList
                                inverted
                                pointerEvents={"none"}
                                showsVerticalScrollIndicator={false}
                                data={this.state.chat}
                                extraData={this.state.latestMessage}
                                renderItem={({item}) => (
                                    <View style={{flexDirection: "row"}}>
                                        <Text style={{
                                            color: item.id <= 0 ? "grey" : item.color,
                                            fontStyle: item.id <= 0 ? "italic" : "plain",
                                            fontWeight: "bold",
                                            fontFamily: "Ubuntu"
                                        }}>
                                            {item.sender + ": "}
                                        </Text>
                                        <Text style={{
                                            color: item.id <= 0 ? "grey" : "white",
                                            fontStyle: item.id <= 0 ? "italic" : "plain",
                                            fontFamily: "Ubuntu"
                                        }}>
                                            {item.message}
                                        </Text>
                                    </View>
                                )}
                                keyExtractor={line => line.id}
                            />
                            <View style={{flexDirection: "row", alignItems: "center"}}>
                                <TextInput
                                    ref={this.chatInput}
                                    value={this.state.message}
                                    style={{
                                        height: "2em",
                                        width: "100%",
                                        backgroundColor: "#ffffff55",
                                        borderWidth: "0.1vmin",
                                        fontFamily: "Ubuntu"
                                    }}
                                    onChangeText={t => {
                                        this.setState({
                                            message: t
                                        })
                                    }}
                                    onKeyPress={k => {
                                        if (this.state.message !== "") {
                                            if (k.key.toLowerCase() === "enter") {
                                                let id = Date.now();
                                                Canvas.send(JSON.stringify({
                                                    type: "chat",
                                                    token: this.state.userData.token,
                                                    content: {
                                                        id: id,
                                                        sender: adjustName(this.state.userData.name, 10),
                                                        message: this.state.message,
                                                        color: this.state.color
                                                    },
                                                    info: {
                                                        size: this.mapSize
                                                    }
                                                }));
                                                this.setState({
                                                    message: "",
                                                    latestMessage: id
                                                });
                                            }
                                        }
                                    }}
                                />
                                <Button
                                    disabled={this.state.message === ""}
                                    disabledColor={"transparent"}
                                    icon={
                                        <AntDesign
                                            name={"rightsquareo"}
                                            color={this.state.message === "" ? "black" : Colors.primary}
                                            size={30}
                                        />
                                    }
                                    hoverIcon={
                                        <AntDesign
                                            name={"rightsquare" + (this.state.message === "" ? "o" : "")}
                                            color={this.state.message === "" ? "black" : Colors.primaryLighter}
                                            size={30}
                                        />
                                    }
                                    buttonStyle={{height: 35, width: 35}}
                                    onPress={() => {
                                        let id = Date.now();
                                        Canvas.send(JSON.stringify({
                                            type: "chat",
                                            token: this.state.userData.token,
                                            content: {
                                                id: id,
                                                sender: adjustName(this.state.userData.name, 10),
                                                message: this.state.message,
                                                color: this.state.color
                                            },
                                            info: {
                                                size: this.mapSize
                                            }
                                        }));
                                        this.setState({
                                            message: "",
                                            latestMessage: id
                                        });
                                    }}
                                />
                            </View>
                        </View>
                    }
                </Animated.View>
                <ConfirmDialog
                    show={this.state.showExit}
                    title={"Sair do canvas"}
                    message={"Deseja sair do canvas e voltar para o menu principal?"}
                    onConfirm={() => {
                        window.open(`${window.location.origin}/Dashboard`, "_self");
                    }}
                    onDismiss={() => this.setState({showExit: false})}
                />
                <Popup
                    show={this.state.showMotd}
                    title={"Regras"}
                    message={
                        "Bem-vindo(a) ao Shiro Canvas, tenha certeza de ler as regras abaixo.\n\n" +
                        "1 - Usar vários bots para manipular o canvas via API é proibido, e resultará no cancelamento do token de acesso.\n" +
                        "2 - Griefing, racismo no chat e toxicidade em geral serão punidos caso seja reportado várias vezes.\n" +
                        "	2.1 - Para reportar, vá até o menu principal e acesse 'Suporte -> Enviar Ticket'.\n" +
                        "	2.2 - O ticket deve conter o nome do usuário a ser reportado e uma breve descrição do que ele(a) fez.\n\n" +
                        "Divirta-se!"
                    }
                    onClose={() => this.setState({showMotd: false})}
                />
            </View>
        )
    }

    renderGame() {
        const opacity = this.delta.interpolate({
            inputRange: [0, 100],
            outputRange: [0.5 * (this.state.cooldown === 0 ? 1 : 0), this.state.cooldown === 0 ? 1 : 0]
        });

        return (
            <SafeAreaView>
                <PanGestureHandler
                    onGestureEvent={evt => {
                        let offsetX = this.state.scrollOffset.x - evt.nativeEvent.velocityX * 25;
                        let offsetY = this.state.scrollOffset.y - evt.nativeEvent.velocityY * 25;
                        this.setState({
                            scrollOffset: {
                                x: Math.max(0, Math.min(offsetX, (this.mapSize * this.state.zoom) - Dimensions.get("window").width)),
                                y: Math.max(0, Math.min(offsetY, (this.mapSize * this.state.zoom) - Dimensions.get("window").height))
                            },
                            justDragging: true
                        }, () => {
                            this.horizontalScroll.current.scrollTo({
                                x: this.state.scrollOffset.x,
                                animated: false
                            });
                            this.verticalScroll.current.scrollTo({
                                y: this.state.scrollOffset.y,
                                animated: false
                            });
                        })
                    }}
                >
                    <View
                        onMouseMove={this.highlight}
                        onWheel={evt => {
                            if ((this.state.zoom > 2 || evt.deltaY < 0) && (this.state.zoom < 32 || evt.deltaY > 0)) {
                                let newZoom = this.state.zoom - (evt.deltaY > 0 ? 1 : -1);
                                let newOffset = {
                                    x: Math.max(0,
                                        Math.min(
                                            (newZoom * this.state.scrollOffset.x / this.state.zoom) + ((Dimensions.get("window").width / this.state.zoom) * (evt.deltaY < 0 ? 1 : -1) / 2), (this.mapSize * newZoom) - Dimensions.get("window").width
                                        )
                                    ),
                                    y: Math.max(0,
                                        Math.min(
                                            (newZoom * this.state.scrollOffset.y / this.state.zoom) + ((Dimensions.get("window").height / this.state.zoom) * (evt.deltaY < 0 ? 1 : -1) / 2), (this.mapSize * newZoom) - Dimensions.get("window").height
                                        )
                                    )
                                }
                                this.setState({
                                    zoom: newZoom,
                                    highlightPos: {x: 0, y: 0},
                                    scrollOffset: newOffset
                                }, () => {
                                    this.horizontalScroll.current.scrollTo({
                                        x: this.state.scrollOffset.x,
                                        animated: false
                                    });
                                    this.verticalScroll.current.scrollTo({
                                        y: this.state.scrollOffset.y,
                                        animated: false
                                    });
                                });
                            }
                        }}
                    >
                        <ScrollView
                            pointerEvents={"none"}
                            ref={this.verticalScroll}
                            showsVerticalScrollIndicator={false}
                            nestedScrollEnabled
                            style={{height: "100vh", width: "100vw"}}
                            scrollEventThrottle={1}
                        >
                            <ScrollView
                                pointerEvents={"none"}
                                ref={this.horizontalScroll}
                                showsHorizontalScrollIndicator={false}
                                horizontal={true}
                                scrollEventThrottle={1}
                            >
                                <View>
                                    <Image
                                        style={{
                                            imageRendering: "pixelated",
                                            height: this.mapSize * this.state.zoom,
                                            width: this.mapSize * this.state.zoom,
                                            top: 0,
                                            left: 0
                                        }}
                                        source={{uri: this.state.canvas}}
                                    />
                                    <View pointerEvents={"box-none"} style={{
                                        position: "absolute",
                                        height: this.state.zoom,
                                        top: this.state.highlightPos.y,
                                        left: this.state.highlightPos.x,
                                        alignItems: "center",
                                        flexDirection: "row"
                                    }}>

                                        <Animated.View
                                            style={{
                                                height: this.state.zoom,
                                                width: this.state.zoom,
                                                opacity: opacity,
                                                backgroundColor: this.state.color + "55",
                                                borderColor: this.state.color,
                                                borderWidth: 1,
                                                borderRadius: "10%"
                                            }}
                                            onMouseUp={() => {
                                                if (!this.state.justDragging && this.state.leftClick && this.state.cooldown <= 0) {
                                                    this.putPixel();
                                                }
                                                this.setState({justDragging: false});
                                            }}
                                        />
                                        {this.makeOutline(
                                            this.state.cooldown,
                                            {
                                                height: this.state.zoom,
                                                width: this.state.zoom
                                            },
                                            {
                                                color: this.state.color,
                                                left: "25%",
                                                fontFamily: "Ubuntu"
                                            }
                                        )}
                                    </View>
                                </View>
                            </ScrollView>
                        </ScrollView>
                    </View>
                </PanGestureHandler>
            </SafeAreaView>
        )
    }

    render() {
        if (this.state.connected) {
            return (
                <View ref={this.game} style={{backgroundColor: Colors.containerDark}}>
                    {this.renderUI()}
                    {this.renderGame()}
                </View>
            )
        } else
            return (
                <SafeAreaView style={{flex: 1}}>
                    <Loading/>
                </SafeAreaView>
            );
    }
}
