import React from "react";
import {StyleSheet} from "react-native-web";
import * as Font from "expo-font";


Font.loadAsync({
	Doreking: require("./assets/fonts/Doreking.ttf"),
	Ubuntu: require("./assets/fonts/Ubuntu-Regular.ttf")
});

export const Styles = StyleSheet.create({
	bg: {
		flex: 1,
		width: "100%",
		height: "100vh",
		alignItems: "center",
		alignSelf: "center",
		justifyContent: "center"
	},
	parallax: {
		position: "absolute",
		height: "100vmax",
		width: "100vmax",
		alignItems: "center",
		alignSelf: "center",
		justifyContent: "center"
	},
	header: {
		fontFamily: "Doreking",
		fontSize: "5vmax"
	},
	headerShadow: {
		textShadowColor: "#DB0075",
		textShadowRadius: "1vmax"
	},
	headerMask: {
		backgroundImage: "url(" + require("./assets/img/mask.jpg") + ")",
		backgroundSize: "cover",
		backgroundClip: "text",
		color: "transparent"
	},
	subheader: {
		fontFamily: "Ubuntu",
		fontSize: "2vmax",
		color: "#f0d5eb"
	},
	fontShadow: {
		textShadowColor: "#000",
		textShadowRadius: "0.5vmax",
		textShadowOffset: {width: 0, height: "0.3vmax"}
	},
	boxShadowDown: {
		shadowColor: "#000",
		shadowRadius: "0.5vmax",
		shadowOffset: {width: 0, height: "0.3vmax"}
	},
	boxShadowUp: {
		shadowColor: "#000",
		shadowRadius: "0.5vmax",
		shadowOffset: {width: 0, height: "-0.3vmax"}
	},
	boxShadowLeft: {
		shadowColor: "#000",
		shadowRadius: "0.5vmax",
		shadowOffset: {height: 0, width: "-0.3vmax"}
	},
	boxShadowRight: {
		shadowColor: "#000",
		shadowRadius: "0.5vmax",
		shadowOffset: {height: 0, width: "0.3vmax"}
	},
	boxShadowRightUp: {
		shadowColor: "#000",
		shadowRadius: "0.5vmax",
		shadowOffset: {width: "0.3vmax", height: "-0.3vmax"}
	},
	textBox: {
		padding: "1vmax",
		borderRadius: "1vmax",
		backgroundColor: "#0006",
		boxShadow: "0px 0.3vmax 0.5vmax #000"
	},
	smallButton: {
		borderBottomLeftRadius: "3vmax",
		borderBottomRightRadius: "3vmax",
		borderTopLeftRadius: "1vmax",
		borderTopRightRadius: "1vmax"
	},
	bigButton: {
		height: "4.5vmax",
		borderBottomLeftRadius: "3vmax",
		borderTopRightRadius: "3vmax",
		borderBottomRightRadius: "1vmax",
		borderTopLeftRadius: "1vmax"
	},
	footer: {
		flexDirection: "row",
		width: "100%",
		padding: "1vmax",
		backgroundColor: "#202225",
		alignItems: "center"
	},
	footerText: {
		fontFamily: "Ubuntu",
		fontSize: "1vmax",
		color: "#99a8ae"
	},
	normalText: {
		fontFamily: "Ubuntu",
		fontSize: "1vmax"
	},
	dshHeader: {
		backgroundColor: "transparent",
		height: "10vh",
		flexDirection: "row",
		zIndex: 999
	},
	dshBody: {
		backgroundColor: "#39343BBB",
		padding: "1vh"
	},
	dshEmbed: {
		backgroundColor: "transparent",
		padding: "1vmin"
	},
	dshLeftMenu: {
		backgroundColor: "#2e2930",
		height: "90vh",
		width: "100%"
	},
	dshFooter: {
		position: "absolute",
		bottom: 0,
		backgroundColor: "darkgray",
		height: "6vh",
		width: "100%"
	},
	dshMenuButton: {
		padding: 0,
		height: "7vmin",
		borderRadius: 0
	},
	dshMenuContent: {
		alignItems: "stretch"
	},
	dshMenuItem: {
		padding: 0,
		borderRadius: 0
	},
	dshMenuButtonText: {
		fontFamily: "Doreking",
		fontSize: "2vmax",
		color: "#f0d5eb"
	},
	dshTitle: {
		fontFamily: "Doreking",
		fontSize: "3vmax",
		color: "#763ff0",
		alignSelf: "flex-start",
		textShadowColor: "black",
		textShadowRadius: "0.5vmin",
		backgroundColor: "#0009",
		borderTopLeftRadius: "2vmin",
		borderTopRightRadius: "2vmin",
		padding: "1vmin"
	},
	dshText: {
		fontFamily: "Ubuntu",
		fontSize: "1.5vmax",
		color: "#f0d5eb",
		margin: "1vmin"
	},
	dshSubText: {
		fontFamily: "Ubuntu",
		fontSize: "1.5vmax",
		color: "white",
		marginLeft: "1vmin",
		marginBottom: "0.5vmin"
	},
	dshSubTextNM: {
		fontFamily: "Ubuntu",
		fontSize: "1.5vmax",
		color: "white",
	},
	dshMediumText: {
		fontFamily: "Doreking",
		fontSize: "1.5vmax",
		textShadowColor: "black",
		textShadowRadius: "0.5vmin",
	},
	dshBigText: {
		fontFamily: "Doreking",
		fontSize: "2vmax",
		textShadowColor: "black",
		textShadowRadius: "0.5vmin",
	},
	dshBiggerText: {
		fontFamily: "Doreking",
		fontSize: "2.5vmax",
		textShadowColor: "black",
		textShadowRadius: "0.5vmin",
	},
	dshDetailText: {
		fontFamily: "Ubuntu",
		fontSize: "1vmax",
		color: "white",
		marginLeft: "1vmin",
		marginBottom: "0.5vmin"
	},
	dshProfileName: {
		fontFamily: "Doreking",
		fontSize: "3vh",
		color: "#f0d5eb"
	},
	dshProfileDetails: {
		fontFamily: "Ubuntu",
		fontSize: "1.5vh",
		color: "#f0d5eb"
	},
	dshProfileBox: {
		padding: "1vh",
		backgroundColor: "#0009",
		borderBottomRightRadius: "5vh"
	},
	dshBox: {
		justifyContent: "space-between",
		padding: "1vh",
		backgroundColor: "#0009",
		borderTopLeftRadius: 0,
		borderTopRightRadius: "2vmin",
		borderBottomLeftRadius: "2vmin",
		borderBottomRightRadius: "2vmin"
	},
	dshProgressBar: {
		height: "1vmin",
		marginLeft: "1vmin",
		borderRadius: "1vmin"
	},
	bigProgressBar: {
		width: "30vmax",
		height: "5vmin",
		marginLeft: "1vmin",
		borderRadius: "1vmin"
	},
	dshError: {
		fontFamily: "Ubuntu",
		fontSize: "1.5vh",
		color: "red"
	},
	dshInput: {
		fontFamily: "Ubuntu",
		color: "white",
		fontSize: "2vh",
		width: "100%"
	},
	dshButton: {
		margin: "1vmin",
		borderRadius: "2vmin"
	},
	dshAvatarChar: {
		fontFamily: "Doreking",
		fontSize: "5vmax",
		color: "#763ff0",
		backgroundColor: "#cbcb00"
	},
	dshConfigChip: {
		backgroundColor: "#f0d5eb",
		color: "#a6008d",
		borderRadius: "1.5vmax",
		paddingHorizontal: "2vmin"
	},
	dshDropdownTitle: {
		flex: 1,
		fontFamily: "Ubuntu",
		backgroundColor: "#39343B",
		borderWidth: 0,
		borderRadius: "2vmax",
		color: "#f0d5eb",
		fontSize: "1.5vmax",
		paddingHorizontal: "2%"
	},
	dshTextArea: {
		fontFamily: "Ubuntu",
		fontSize: "1.5vmax",
		borderWidth: "0.2vmin",
		borderRadius: "1vmax",
		color: "#f0d5eb",
		borderColor: "#f0d5eb",
		padding: "1vmin",
		height: "20vmin",
		width: "100%"
	},
	dshHighlight: {
		fontFamily: "Ubuntu",
		backgroundColor: "#db00bb99",
		borderRadius: "1vmin",
		fontWeight: "bold"
	},
	dshSpinner: {
		fontFamily: "Ubuntu",
		color: "#db00bb",
		fontWeight: "bold",
		minWidth: 1,
		appearance: "textfield"
	},
	dshListItem: {
		backgroundColor: "#7b004b88",
		paddingVertical: 0,
		borderRadius: "2vh"
	},
	dshListTitle: {
		fontFamily: "Ubuntu",
		fontSize: 18,
		color: "#f0d5eb",
	},
	dshListSubtitle: {
		fontFamily: "Ubuntu",
		fontSize: 15,
		color: "#f0d5eb",
	},
	dshOfferBalloon: {
		flexDirection: "row",
		borderRadius: "0.5vmax",
		backgroundColor: "#2e2930bb",
		marginBottom: "1vmin",
		padding: "0.5vmin",
		margin: "0.5vmin",
		justifyContent: "space-between",
		alignItems: "center"
	},
	srchCheckBox: {
		padding: "2%",
		marginHorizontal: "1%",
		marginVertical: "2%",
		marginLeft: "2%",
		marginRight: "2%",
		backgroundColor: "#4f4f4f",
		borderWidth: 0,
		borderRadius: "1vmin"
	},
	offerDetailsBox: {
		width: "90%",
		height: "90%",
		backgroundColor: "#39343B",
		alignSelf: "center",
		justifySelf: "middle",
		borderRadius: "1vmin",
		flexDirection: "row",
		padding: "1vmin"
	},
	alertBox: {
		width: "50%%",
		backgroundColor: "white",
		alignSelf: "center",
		justifySelf: "middle",
		borderRadius: "1vmin",
		padding: "1vmin"
	},
	offerInfoContainer: {
		margin: "1vmin",
		padding: "1vmin",
		backgroundColor: "#4f4f4f",
		borderRadius: "1vmin",
		justifyContent: "middle"
	}
});

export const Colors = {
	primary: "#db00bb",
	primaryLighter: "#bf00ff",
	primaryDarker: "#a6008d",
	primaryDark: "#7b004b",
	secondary: "#4f4f4f",
	secondaryLighter: "#737373",
	secondaryDark: "#3b3b3b",
	container: "#39343B",
	containerDark: "#2e2930",
	pinkText: "#f0d5eb",
	purpleText: "#763ff0",
	error: "#db002a"
};

export const Discord = {
	primary: "#5865F2",
	success: "#489861",
	danger: "#ED4245",
	backgroundDark: "#202225",
	background: "#2f3136",
	backgroundLight: "#36393f"
};