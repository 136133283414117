import React from "react";
import {calcSize, compare, getColor, n} from "../../Helper";
import {Colors, Styles} from "../../Styles";
import {Text, View} from "react-native-web";
import Collapsible from "react-native-collapsible";
import Popup from "../../components/Popup";
import Error from "../../components/Error";
import {Server as Socket} from "../../App";
import {Dimensions, FlatList, ImageBackground, ScrollView} from "react-native";
import {CheckBox, Image, Input, SearchBar} from "react-native-elements";
import Sparkle from "react-sparkle";
import {ActivityIndicator, Modal, TouchableRipple} from "react-native-paper";
import TitledText from "../../components/TitledText";

export default class Store extends React.Component {
	constructor(props) {
		super(props);
		this.resize = () => calcSize(this);
		this.filterByName = t => {
			this.setState({
				criteria: {
					...this.state.criteria,
					name: t.toLowerCase()
				}
			}, this.applyCriteria);
		};
		this.filterByType = i => {
			this.setState({
				criteria: {
					...this.state.criteria,
					type: {
						kawaipon: i === 1 ? !this.state.criteria.type.kawaipon : this.state.criteria.type.kawaipon,
						evogear: i === 2 ? !this.state.criteria.type.evogear : this.state.criteria.type.evogear,
						field: i === 3 ? !this.state.criteria.type.field : this.state.criteria.type.field
					}
				}
			}, this.applyCriteria);
		};
		this.filterByPrice = (i, v) => {
			this.setState({
				criteria: {
					...this.state.criteria,
					minPrice: i === 1 ? parseInt(v) || -1 : this.state.criteria.minPrice,
					maxPrice: i === 2 ? parseInt(v) || -1 : this.state.criteria.maxPrice,
				}
			}, this.applyCriteria);
		};
		this.filterByRarity = v => {
			this.setState({
				criteria: {
					...this.state.criteria,
					rarity: parseInt(v) || -1
				}
			}, this.applyCriteria);
		};
		this.filterByFoil = () => {
			this.setState({
				criteria: {
					...this.state.criteria,
					foil: !this.state.criteria.foil
				}
			}, this.applyCriteria);
		};
		this.sort = v => {
			if (this.state.sort === v) return;
			this.setState({
				criteria: {
					...this.state.criteria,
					sort: v
				}
			}, this.applyCriteria);
		};
		this.renderItem = ({item}) => item.render;

		this.state = {
			userData: JSON.parse(window.localStorage.getItem("user")),
			screenLoaded: false,
			vertical: false,
			showCard: false,
			alertMsg: null,
			offers: [],
			currCard: null,
			loaded: false,
			criteria: {
				name: "",
				type: {
					kawaipon: true,
					evogear: true,
					field: true
				},
				minPrice: -1,
				maxPrice: -1,
				rarity: -1,
				sort: "price"
			}
		};
	}

	componentDidMount() {
		calcSize(this);
		this.setState({screenLoaded: true});

		Socket.onmessage = ({data}) => {
			let d = JSON.parse(data);

			if (d.type === "store") {
				this.allOffers = Object.values(d.data)
					.filter(item => item.card)
					.map(item => {
						item.card = JSON.parse(item.card);
						item.render = this.renderOffers(item);
						return item;
					})
					.sort((a, b) => compare(a.price, b.price));

				this.setState({
					offers: this.allOffers,
					loaded: true
				});
			} else if (d.type === "card_info") {
				this.setState({
					showCard: true,
					alertMsg: null,
					currCard: {
						...this.state.currCard,
						image: {
							normal: d.data.normal ? {uri: `data:image/png;base64,${d.data.normal}`} : null,
							alt: d.data.alt ? {uri: `data:image/png;base64,${d.data.alt}`} : null
						},
						info: d.data.info && JSON.parse(d.data.info)
					}
				});
			}
		};

		Socket.send(JSON.stringify({
			type: "store",
			data: {
				token: this.state.userData.token
			}
		}));
	}

	showCard(offer) {
		Socket.send(JSON.stringify({
			type: "card_info",
			data: {
				token: this.state.userData.token,
				id: offer.card.id,
				foil: offer.foil || false,
				type: offer.type.toUpperCase()
			}
		}));

		this.setState({
			currCard: offer,
			alertMsg: "Carregando dados sobre a carta..."
		});
	}

	hideCard() {
		this.setState({
			currCard: null,
			showCard: false
		});
	}

	getRarity(rarity) {
		switch (rarity) {
			case 1:
				return "Comum";
			case 2:
				return "Incomum";
			case 3:
				return "Raro";
			case 4:
				return "Ultra-raro";
			case 5:
				return "Lendário";
		}
	}

	getCharm(charm) {
		switch (charm) {
			case "SPELLSHIELD":
				return "Escudo de feitiços";
			case "SPELLMIRROR":
				return "Reflexo de feitiços";
			case "TIMEWARP":
				return "Salto temporal";
			case "DOUBLETAP":
				return "Toque duplo";
			case "DOPPELGANGER":
				return "Clone";
			case "SOULLINK":
				return "Vínculo espiritual";
			case "SPELL":
				return "Magia";
			case "ARMORPIERCING":
				return "Penetração de armadura";
			case "AGILITY":
				return "Agilidade";
		}
	}

	getEffect({description, charm}) {
		switch (charm.id) {
			case "SPELLSHIELD":
				return "Bloqueia 1 feitiço com alvo na carta equipada.";
			case "SPELLMIRROR":
				return "Reflete 1 feitiço com alvo na carta equipada.";
			case "TIMEWARP":
				return "Ativa efeitos de turno da carta equipada.";
			case "DOUBLETAP":
				return "Ativa efeitos de invocação da carta equipada.";
			case "DOPPELGANGER":
				return "Cria um clone da carta equipada com 75% do poder da original.";
			case "SOULLINK":
				return "Bloqueia modificadores de campo na carta equipada.";
			case "SPELL":
				return description;
			case "ARMORPIERCING":
				return "Causa dano direto caso o alvo esteja defendendo.";
			case "AGILITY":
				return "Aumenta em 10% a chance de esquiva da carta equipada.";
		}
	}

	getOfferDetails() {
		switch (this.state.currCard.type) {
			case "kawaipon":
				return (
					<View style={{flex: 1, justifyContent: "middle"}}>
						<View style={Styles.offerInfoContainer}>
							<Image
								resizeMode={"contain"}
								style={{height: "35vh"}}
								source={this.state.currCard.image.alt || this.state.currCard.image.normal}
								PlaceholderContent={<ActivityIndicator size={"large"}/>}
							/>
						</View>
						<View style={[Styles.offerInfoContainer, {flex: 1}]}>
							<View>
								<TitledText
									title={"Tipo: "}
									text={"Kawaipon" + (this.state.currCard.image.alt ? "/Campeão" : "")}
									titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
									textStyle={[Styles.normalText, {color: "white"}]}
								/>
								<TitledText
									title={"Nome: "}
									text={this.state.currCard.card.name}
									titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
									textStyle={[Styles.normalText, {color: "white"}]}
								/>
								<TitledText
									title={"Anime: "}
									text={this.state.currCard.card.anime.name}
									titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
									textStyle={[Styles.normalText, {color: "white"}]}
								/>
								<TitledText
									title={"Raridade: "}
									text={this.getRarity(this.state.currCard.card.rarity)}
									titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
									textStyle={[Styles.normalText, {color: getColor(this.state.currCard.card.rarity)}]}
								/>
								{
									this.state.currCard.image.alt &&
									<View>
										<TitledText
											title={"Mana: "}
											text={this.state.currCard.info.champion.mana}
											titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
											textStyle={[Styles.normalText, {color: "cyan"}]}
										/>
										<TitledText
											title={"Atributos: "}
											text={
												<View style={{flexDirection: "row"}}>
													<Text style={{color: "red"}}>
														ATK: {this.state.currCard.info.champion.attack}
													</Text>
													<Text> | </Text>
													<Text style={{color: "chartreuse"}}>
														DEF: {this.state.currCard.info.champion.defense}
													</Text>
												</View>
											}
											titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
											textStyle={[Styles.normalText, {color: "white"}]}
										/>
										<TitledText
											title={"Descrição: "}
											text={this.state.currCard.info.champion.description}
											titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
											textStyle={[Styles.normalText, {color: "white"}]}
										/>
									</View>
								}
							</View>
						</View>
					</View>
				);
			case "evogear":
				return (
					<View style={{flex: 1, justifyContent: "middle"}}>
						<View style={Styles.offerInfoContainer}>
							<Image
								resizeMode={"contain"}
								style={{height: "35vh"}}
								source={this.state.currCard.image.alt || this.state.currCard.image.normal}
								PlaceholderContent={<ActivityIndicator size={"large"}/>}
							/>
						</View>
						<View style={[Styles.offerInfoContainer, {flex: 1}]}>
							<View>
								<TitledText
									title={"Tipo: "}
									text={this.state.currCard.card.equipment.charm?.id === "SPELL" ? "Feitiço" : "Equipamento"}
									titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
									textStyle={[Styles.normalText, {color: "white"}]}
								/>
								<TitledText
									title={"Nome: "}
									text={this.state.currCard.card.name}
									titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
									textStyle={[Styles.normalText, {color: "white"}]}
								/>
								{
									this.state.currCard.card.anime &&
									<TitledText
										title={"Anime: "}
										text={this.state.currCard.card.anime.name}
										titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
										textStyle={[Styles.normalText, {color: "white"}]}
									/>
								}
								<TitledText
									title={"Tier: "}
									text={n("⭐", this.state.currCard.card.equipment.tier)}
									titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
									textStyle={[Styles.normalText, {color: "white"}]}
								/>
								{
									this.state.currCard.card.equipment.attack !== 0 && this.state.currCard.card.equipment.defense !== 0 &&
									<TitledText
										title={"Atributos: "}
										text={
											<View style={{flexDirection: "row"}}>
												<Text style={{color: "red"}}>
													ATK: {this.state.currCard.card.equipment.attack}
												</Text>
												<Text> | </Text>
												<Text style={{color: "chartreuse"}}>
													DEF: {this.state.currCard.card.equipment.defense}
												</Text>
											</View>
										}
										titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
										textStyle={[Styles.normalText, {color: "white"}]}
									/>
								}
								{
									this.state.currCard.card.equipment.charm && this.state.currCard.card.equipment.charm.id !== "SPELL" &&
									<TitledText
										title={"Amuleto: "}
										text={this.getCharm(this.state.currCard.card.equipment.charm.id)}
										titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
										textStyle={[Styles.normalText, {color: "white"}]}
									/>
								}
								{
									this.state.currCard.card.equipment.charm &&
									<View style={{flexDirection: "row"}}>
										<TitledText
											title={"Efeito: "}
											text={
												<View style={{flexDirection: "row"}}>
													{
														this.state.currCard.card.equipment.charm.image ?
														<Image
															style={{
																height: "1vmax",
																width: "1vmax",
																marginRight: "0.1vmax"
															}}
															source={{uri: `data:image/png;base64,${this.state.currCard.card.equipment.charm.image}`}}
														/> : null
													}
													<Text>{this.getEffect(this.state.currCard.card.equipment)}</Text>
												</View>
											}
											titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
											textStyle={[Styles.normalText, {color: "white"}]}
										/>
									</View>
								}
							</View>
						</View>
					</View>
				);
			case "field":
				return (
					<View style={{flex: 1, justifyContent: "middle"}}>
						<View style={Styles.offerInfoContainer}>
							<Image
								resizeMode={"contain"}
								style={{height: "35vh"}}
								source={this.state.currCard.image.alt || this.state.currCard.image.normal}
								PlaceholderContent={<ActivityIndicator size={"large"}/>}
							/>
						</View>
						<View style={[Styles.offerInfoContainer, {flex: 1}]}>
							<View>
								<TitledText
									title={"Tipo: "}
									text={"Campo"}
									titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
									textStyle={[Styles.normalText, {color: "white"}]}
								/>
								<TitledText
									title={"Nome: "}
									text={this.state.currCard.card.name}
									titleStyle={[Styles.normalText, {color: Colors.pinkText}]}
									textStyle={[Styles.normalText, {color: "white"}]}
								/>
							</View>
						</View>
					</View>
				);
		}
	}

	renderOffers(item) {
		return (
			<TouchableRipple key={item.id} onPress={() => this.showCard(item)}>
				<View
					style={[Styles.dshOfferBalloon, {backgroundColor: item.card.foil ? "rgba(173,176,94,0.73)" : "#2e2930bb"}]}>
					<View>
						<Text style={[Styles.dshBiggerText, {color: getColor(item.card.rarity)}]}>
							{item.card.name}
						</Text>
						{
							item.type === "kawaipon" &&
							<Text style={Styles.dshSubTextNM}>{item.card.anime.name}</Text>
						}
						{
							item.type === "evogear" &&
							<Text style={Styles.dshSubTextNM}>EvoGear {n("⭐", item.card.equipment.tier)}</Text>
						}
						{
							item.type === "field" &&
							<Text style={Styles.dshSubTextNM}>Campo</Text>
						}
					</View>
					<View style={{alignItems: "flex-end"}}>
						<Text style={[Styles.dshBigText, {color: Colors.pinkText, alignSelf: "flex-end"}]}>
							{item.price.toLocaleString()} CR
						</Text>
						<Text style={Styles.dshSubTextNM}>{item.seller}</Text>
					</View>
					{
						item.foil &&
						<Sparkle
							color={"random"}
							fadeOutSpeed={10}
							count={20}
							minSize={5}
							maxSize={15}
							overflowPx={0}
							flicker={false}
						/>
					}
				</View>
			</TouchableRipple>
		)
	}

	applyCriteria() {
		if (!this.allOffers) return;
		this.setState({
			offers: this.allOffers
				.filter(c => c.card.name.toLowerCase().indexOf(this.state.criteria.name) !== -1
					&& (
						(this.state.criteria.type.kawaipon && c.type === "kawaipon")
						|| (this.state.criteria.type.evogear && c.type === "evogear")
						|| (this.state.criteria.type.field && c.type === "field")
					)
					&& (
						(this.state.criteria.minPrice === -1
							|| c.price >= this.state.criteria.minPrice)
						&& (this.state.criteria.maxPrice === -1
							|| c.price <= this.state.criteria.maxPrice)
					)
					&& (
						this.state.criteria.rarity === -1
						|| c.card.rarity === this.state.criteria.rarity
						|| c.card.tier === this.state.criteria.rarity
					)
					&& (
						!this.state.criteria.foil
						|| c.card.foil
					)
				)
				.sort((a, b) => {
					switch (this.state.criteria.sort) {
						case "price":
							return compare(a.price, b.price);
						case "name":
							return compare(a.card.id, b.card.id);
						case "rarity":
							return compare(a.card.rarity || a.card.tier, b.card.rarity || b.card.tier) || compare(b.type, a.type);
						case "anime":
							return compare(a.card.anime?.id || a.type, b.card.anime?.id || b.type);
						case "seller":
							return compare(a.seller, b.seller) || compare(b.type, a.type);
						case "type":
							return compare(b.type, a.type);
					}
				})
		});
	}

	render() {
		return (
			<View style={Styles.dshEmbed} onLayout={this.resize}>
				<Text style={Styles.dshTitle}>Loja de cartas</Text>
				<Collapsible collapsed={!this.state.screenLoaded} style={[Styles.dshBox, {flexDirection: "row"}]}>
					{
						!this.state.vertical &&
						<View style={{
							flex: 2,
							backgroundColor: Colors.container + "bb",
							borderRadius: "0.5vmin",
						}}>
							<SearchBar
								round
								value={this.state.criteria.name}
								placeholder={"Pesquisar..."}
								placeholderTextColor={Colors.secondaryDark}
								onChangeText={this.filterByName}
								inputStyle={[Styles.normalText, {
									width: "100%",
									padding: "1%",
									color: "black"
								}]}
								containerStyle={{
									width: "96%",
									margin: "2%",
									paddingHorizontal: 0,
									backgroundColor: "transparent",
									borderTopWidth: 0,
									borderBottomWidth: 0
								}}
								inputContainerStyle={{
									backgroundColor: Colors.secondaryLighter + "bb"
								}}
							/>
							<View style={{
								margin: "2%",
								backgroundColor: Colors.secondaryDark,
								borderRadius: "1vmin"
							}}>
								<Text style={[Styles.dshMediumText, {
									alignSelf: "center",
									color: "white",
									margin: "2%"
								}]}>Exibir:</Text>
								<CheckBox
									containerStyle={Styles.srchCheckBox}
									title={"Kawaipon"}
									textStyle={[Styles.normalText, {
										color: this.state.criteria.type.kawaipon ? Colors.pinkText : Colors.secondaryDark
									}]}
									checkedColor={Colors.primary}
									checked={this.state.criteria.type.kawaipon}
									onPress={() => this.filterByType(1)}
								/>
								<CheckBox
									containerStyle={Styles.srchCheckBox}
									title={"EvoGear"}
									textStyle={[Styles.normalText, {
										color: this.state.criteria.type.evogear ? Colors.pinkText : Colors.secondaryDark
									}]}
									checkedColor={Colors.primary}
									checked={this.state.criteria.type.evogear}
									onPress={() => this.filterByType(2)}
								/>
								<CheckBox
									containerStyle={Styles.srchCheckBox}
									title={"Campo"}
									textStyle={[Styles.normalText, {
										color: this.state.criteria.type.field ? Colors.pinkText : Colors.secondaryDark
									}]}
									checkedColor={Colors.primary}
									checked={this.state.criteria.type.field}
									onPress={() => this.filterByType(3)}
								/>
							</View>
							<View style={{
								flexDirection: "row",
								margin: "2%",
								backgroundColor: Colors.secondaryDark,
								borderRadius: "1vmin",
								alignItems: "center"
							}}>
								<Text style={[Styles.dshMediumText, {flex: 2, color: "white", margin: "2%"}]}>Preço
									mínimo:</Text>
								<Input
									value={this.state.criteria.minPrice > -1 ? this.state.criteria.minPrice : ""}
									onChangeText={v => {
										if (parseInt(v) <= this.state.criteria.maxPrice || this.state.criteria.maxPrice === -1)
											this.filterByPrice(1, v);
									}}
									inputStyle={[Styles.normalText, {
										width: "100%",
										padding: "1%",
										textAlign: "center"
									}]}
									containerStyle={{
										flex: 1,
										margin: "2%",
										paddingHorizontal: 0
									}}
									inputContainerStyle={{
										backgroundColor: Colors.secondaryLighter + "bb",
										borderRadius: "1vmin"
									}}
								/>
							</View>
							<View style={{
								flexDirection: "row",
								margin: "2%",
								backgroundColor: Colors.secondaryDark,
								borderRadius: "1vmin",
								alignItems: "center"
							}}>
								<Text style={[Styles.dshMediumText, {flex: 2, color: "white", margin: "2%"}]}>Preço
									máximo:</Text>
								<Input
									value={this.state.criteria.maxPrice > -1 ? this.state.criteria.maxPrice : ""}
									onChangeText={v => {
										if (parseInt(v) >= this.state.criteria.minPrice || this.state.criteria.minPrice === -1)
											this.filterByPrice(2, v);
									}}
									inputStyle={[Styles.normalText, {
										width: "100%",
										padding: "1%",
										textAlign: "center"
									}]}
									containerStyle={{
										flex: 1,
										margin: "2%",
										paddingHorizontal: 0
									}}
									inputContainerStyle={{
										backgroundColor: Colors.secondaryLighter + "bb",
										borderRadius: "1vmin"
									}}
								/>
							</View>
							<View style={{
								flexDirection: "row",
								margin: "2%",
								backgroundColor: Colors.secondaryDark,
								borderRadius: "1vmin",
								alignItems: "center"
							}}>
								<Text style={[Styles.dshMediumText, {
									flex: 2,
									color: "white",
									margin: "2%"
								}]}>Raridade:</Text>
								<Input
									value={this.state.criteria.rarity > -1 ? this.state.criteria.rarity : ""}
									onChangeText={v => {
										let val = parseInt(v);
										if (val > 0 && val < 6 || !v)
											this.filterByRarity(v);
									}}
									inputStyle={[Styles.normalText, {
										width: "100%",
										padding: "1%",
										textAlign: "center"
									}]}
									containerStyle={{
										flex: 1,
										margin: "2%",
										paddingHorizontal: 0
									}}
									inputContainerStyle={{
										backgroundColor: Colors.secondaryLighter + "bb",
										borderRadius: "1vmin"
									}}
								/>
							</View>
							<View style={{
								margin: "2%",
								backgroundColor: Colors.secondaryDark,
								borderRadius: "1vmin",
							}}>
								<CheckBox
									containerStyle={Styles.srchCheckBox}
									title={"Apenas cromadas"}
									textStyle={[Styles.normalText, {
										color: this.state.criteria.foil ? Colors.pinkText : Colors.secondaryDark
									}]}
									checkedColor={Colors.primary}
									checked={this.state.criteria.foil}
									onPress={this.filterByFoil}
								/>
							</View>
							<View style={{
								margin: "2%",
								backgroundColor: Colors.secondaryDark,
								borderRadius: "1vmin"
							}}>
								<Text style={[Styles.dshMediumText, {
									alignSelf: "center",
									color: "white",
									margin: "2%"
								}]}>Ordenar por:</Text>
								<View style={{flexDirection: "row"}}>
									<View style={{flex: 1}}>
										<CheckBox
											containerStyle={Styles.srchCheckBox}
											title={"Preço"}
											textStyle={[Styles.normalText, {
												color: this.state.criteria.sort === "price" ? Colors.pinkText : Colors.secondaryDark
											}]}
											checkedColor={Colors.primary}
											checked={this.state.criteria.sort === "price"}
											onPress={() => this.sort("price")}
										/>
										<CheckBox
											containerStyle={Styles.srchCheckBox}
											title={"Nome"}
											textStyle={[Styles.normalText, {
												color: this.state.criteria.sort === "name" ? Colors.pinkText : Colors.secondaryDark
											}]}
											checkedColor={Colors.primary}
											checked={this.state.criteria.sort === "name"}
											onPress={() => this.sort("name")}
										/>
										<CheckBox
											containerStyle={Styles.srchCheckBox}
											title={"Raridade/Tier"}
											textStyle={[Styles.normalText, {
												color: this.state.criteria.sort === "rarity" ? Colors.pinkText : Colors.secondaryDark
											}]}
											checkedColor={Colors.primary}
											checked={this.state.criteria.sort === "rarity"}
											onPress={() => this.sort("rarity")}
										/>
									</View>
									<View style={{flex: 1}}>
										<CheckBox
											containerStyle={Styles.srchCheckBox}
											title={"Anime"}
											textStyle={[Styles.normalText, {
												color: this.state.criteria.sort === "anime" ? Colors.pinkText : Colors.secondaryDark
											}]}
											checkedColor={Colors.primary}
											checked={this.state.criteria.sort === "anime"}
											onPress={() => this.sort("anime")}
										/>
										<CheckBox
											containerStyle={Styles.srchCheckBox}
											title={"Vendedor"}
											textStyle={[Styles.normalText, {
												color: this.state.criteria.sort === "seller" ? Colors.pinkText : Colors.secondaryDark
											}]}
											checkedColor={Colors.primary}
											checked={this.state.criteria.sort === "seller"}
											onPress={() => this.sort("seller")}
										/>
										<CheckBox
											containerStyle={Styles.srchCheckBox}
											title={"Tipo"}
											textStyle={[Styles.normalText, {
												color: this.state.criteria.sort === "type" ? Colors.pinkText : Colors.secondaryDark
											}]}
											checkedColor={Colors.primary}
											checked={this.state.criteria.sort === "type"}
											onPress={() => this.sort("type")}
										/>
									</View>
								</View>
							</View>
						</View>
					}
					{
						this.state.loaded ?
							<ScrollView
								style={{flex: 7, height: "75vh"}}
								scrollEventThrottle={400}
								showsVerticalScrollIndicator={false}
							>
								<FlatList
									contentContainerStyle={{alignSelf: "stretch"}}
									data={this.state.offers}
									renderItem={this.renderItem}
									keyExtractor={i => i.id}
									removeClippedSubviews
									initialNumToRender={15}
									maxToRenderPerBatch={1}
									windowSize={5}
								/>
							</ScrollView>
							: <View style={{flex: 7, height: "75vh", justifyContent: "center"}}>
								<ActivityIndicator
									size={(this.state.vertical ? Dimensions.get("window").width : Dimensions.get("window").height) * 0.25}
								/>
							</View>
					}
				</Collapsible>
				{
					this.state.showCard &&
					<Modal
						visible
						onDismiss={() => this.hideCard()}
						contentContainerStyle={Styles.offerDetailsBox}
					>
						{this.getOfferDetails()}
						<View style={{flex: 3, padding: "1vmin", alignItems: "center"}}>

						</View>
					</Modal>
				}
				{
					this.state.alertMsg &&
					<Modal
						visible
						dismissable={false}
						contentContainerStyle={Styles.alertBox}
					>
						<Text>{this.state.alertMsg}</Text>
					</Modal>
				}
			</View>
		);
	}
}