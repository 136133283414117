import React from "react";
import {Text, View} from "react-native-web";
import {Styles} from "../../Styles";
import Collapsible from "react-native-collapsible";
import {ProgressBar} from "react-native-paper";
import {calcSize, compare} from "../../Helper";

export default class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.resize = () => calcSize(this);

		this.state = {
			userData: JSON.parse(window.localStorage.getItem("user")),
			serverData: JSON.parse(window.localStorage.getItem("servers")),
			screenLoaded: false,
			vertical: false
		};
	}

	componentDidMount() {
		calcSize(this);
		this.setState({screenLoaded: true});

		this.guildLevelDetail = this.state.serverData
			.sort((a, b) => compare(a.name, b.name))
			.map(s => {
				const profile = this.state.userData.profiles.find(p => p.sid === s.guildID);
				return (
					<View key={s.guildID} style={{marginVertical: "1vh"}}>
						<Text style={Styles.dshSubText}>
							{s.name} (Level: {profile.level})
						</Text>
						<Text style={Styles.dshDetailText}>
							XP: {profile.xp.toLocaleString()}/{(profile.level ** 2 * 100).toLocaleString()}
						</Text>
						<ProgressBar
							progress={(profile.xp / 100 - Math.pow(profile.level - 1, 2)) / (Math.pow(profile.level, 2) - Math.pow(profile.level - 1, 2))}
							style={Styles.dshProgressBar}
						/>
					</View>
				)
			});
	}

	showExceed() {
		if (this.state.userData.exceed.id !== -1) return (
			<View>
				<Text style={Styles.dshText}>Exceed: {this.state.userData.exceed.name}</Text>
				<Text style={Styles.dshSubText}>Posição: {this.state.userData.exceed.ranking + 1}.º</Text>
				<Text style={Styles.dshSubText}>Pontos
					contribuídos: {this.state.userData.profiles.reduce((a, {xp}) => a + xp, 0)}</Text>
			</View>
		)
	}

	render() {
		return (
			<View style={Styles.dshEmbed} onLayout={this.resize}>
				<Text style={Styles.dshTitle}>Dados</Text>
				<Collapsible collapsed={!this.state.screenLoaded} style={Styles.dshBox}>
					<Text style={Styles.dshText}>Level (por servidor):</Text>
					<View>
						{this.guildLevelDetail}
					</View>

					<Text style={Styles.dshText}>
						Waifu: {this.state.userData.waifu.length === 0 ? "Nenhuma" : this.state.userData.waifu}
					</Text>

					{this.showExceed}

					<Text style={Styles.dshText}>Total de emblemas: {this.state.userData.badges.length}</Text>
					<Text style={Styles.dshText}>Créditos: {this.state.userData.credits.toLocaleString()}</Text>
				</Collapsible>
			</View>
		);
	}
}